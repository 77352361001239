<template>
  <a-skeleton-avatar-props :shape="shape" :size="size"></a-skeleton-avatar-props>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsSkeletonAvatarProps",
  props: {
    shape: {
      type: String,
      default: undefined,
    },
    size: {
      type: [Number, String],
      default: undefined,
    },
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
