<template>
    <a-upload-dragger class="cgs-upload-dragger" :accept="accept" :action="action" :beforeUpload="beforeUpload" :customRequest="customRequest" :data:="data"
      :directory="directory" :disabled="disabled" v-model:fileList="showFileList" :headers="headers" :isImageUrl="isImageUrl"
      :listType="listType" :maxCount="maxCount" :method="method" :multiple="multiple" :name="name"
      :openFileDialogOnClick="openFileDialogOnClick" :previewFile="previewFile" :progress="progress"
      :showUploadList="showUploadList" :supportServerRender="supportServerRender" :withCredentials="withCredentials"
      @download="downloadHandler" @drop="dropHandler" @preview="previewHandler" @reject="rejectHandler"
      @remove="removeHandler">
      <template #downloadIcon="{file: UploadFile}" v-if="'downloadIcon' in slots">
        <slot name="downloadIcon" :file="UploadFile"></slot>
      </template>
      <template #iconRender="{file: UploadFile, listType: UploadListType}" v-if="'iconRender' in slots">
        <slot name="iconRender" :file="UploadFile" :listType="UploadListType"></slot>
      </template>
      <template #itemRender="{originNode: VNode, file: UploadFile, fileList: [], actions}" v-if="'itemRender' in slots">
        <slot name="itemRender" :originNode="VNode" :file="UploadFile" :filelist="[]" :actions="actions"></slot>
      </template>
      <template #previewIcon="{file: UploadFile}" v-if="'previewIcon' in slots">
        <slot name="previewIcon" :file="UploadFile"></slot>
      </template>
      <template #removeIcon="{file: UploadFile}" v-if="'removeIcon' in slots">
        <slot name="removeIcon" :file="UploadFile"></slot>
      </template>
      <slot></slot>
    </a-upload-dragger>
  </template>
  
  <script>
    import {
      computed,
      defineComponent,
      useSlots
    } from "vue";
    export default defineComponent({
      name: "cgsUploadDragger",
      props: {
        accept: {
          type: String,
          default: ''
        },
        action: {
          type: [String, Function],
          default: ''
        },
        beforeUpload: {
          type: [Function, Promise],
          default: undefined
        },
        customRequest: {
          type: Function,
          default: undefined
        },
        data: {
          type: [Object, Function],
          default: undefined
        },
        directory: {
          type: Boolean,
          default: false
        },
        disabled: {
          type: Boolean,
          default: false
        },
        fileList: {
          type: Array,
          default: () => []
        },
        headers: {
          type: Object,
          default: () => ({})
        },
        isImageUrl: {
          type: Boolean,
          default: undefined
        },
        listType: {
          type: String,
          default: "text"
        },
        maxCount: {
          type: Number,
          default: undefined
        },
        method: {
          type: String,
          default: "post"
        },
        multiple: {
          type: Boolean,
          default: false
        },
        name: {
          type: String,
          default: "file"
        },
        openFileDialogOnClick: {
          type: Boolean,
          default: true
        },
        previewFile: {
          type: Function,
          default: undefined
        },
        progress: {
          type: Object,
          // eslint-disable-next-line vue/require-valid-default-prop
          default: {
            strokeWidth: 2,
            showInfo: false
          }
        },
        showUploadList: {
          type: [Boolean, Object],
          default: true
        },
        supportServerRender: {
          type: Boolean,
          default: false
        },
        withCredentials: {
          type: Boolean,
          default: false
        }
      },
      setup(props, ctx) {
        const slots = useSlots();
        const showFileList = computed({
          get: () => props.fileList,
          set: (val) => {
            ctx.emit('update:fileList', val)
          }
        })
        const changeHandler = (info) => {
          ctx.emit('change', info)
        }
        const downloadHandler = (file) => {
          ctx.emit('download', file)
        }
        const dropHandler = (event) => {
          ctx.emit('drop', event)
        }
        const previewHandler = (file) => {
          ctx.emit('preview', file)
        }
        const rejectHandler = (fileList) => {
          ctx.emit('reject', fileList)
        }
        const removeHandler = (file) => {
          ctx.emit('remove', file)
        }
        return {
          slots,
          showFileList,
          changeHandler,
          downloadHandler,
          dropHandler,
          previewHandler,
          rejectHandler,
          removeHandler
        }
      }
    })
  </script>
  
  <style scoped>
  
  </style>