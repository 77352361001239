export const lightThemeVarsCollection = {
    '--cgs-primary-color': '#23C5D0',
    '--cgs-primary-color-08': "rgba(35, 197, 208, 0.8)",
    '--cgs-secondary-color-1': '#DF6231',
    '--cgs-secondary-color-2': '#5FD3FC',
    '--cgs-secondary-color-3': '#23C635',
    '--cgs-secondary-color-4': '#67A1F8',
    '--cgs-card-color': '#FFFFFF',
    '--cgs-selected-color': '#ECECEC',
    '--cgs-selected-color-08': 'rgba(236, 236, 236, 0.8)',
    '--cgs-background-color': '#F9FAFB',
    '--cgs-font-color-1': '#333333',
    '--cgs-font-color-2': '#5C5F65',
    '--cgs-font-color-3': '#999999'
}

export const darkThemeVarsCollection = {
    '--cgs-primary-color': '#23C5D0',
    '--cgs-primary-color-08': "rgba(35, 197, 208, 0.8)",
    '--cgs-secondary-color-1': '#DF6231',
    '--cgs-secondary-color-2': '#5FD3FC',
    '--cgs-secondary-color-3': '#23C635',
    '--cgs-secondary-color-4': '#67A1F8',
    '--cgs-card-color': '#111219',
    '--cgs-selected-color': 'rgba(255, 255,255, 0.15)',
    '--cgs-selected-color-08': 'rgba(236, 236, 236, 0.12)',
    '--cgs-background-color': '#16171D',
    '--cgs-font-color-1': '#FFFFFF',
    '--cgs-font-color-2': '#FFFFFF',
    '--cgs-font-color-3': '#FFFFFF'
}

// export const defaultThemeVars = lightThemeVars

export default {
    light: lightThemeVarsCollection,
    dark: darkThemeVarsCollection
}