<template>
  <a-descriptions :bordered="bordered" :colon="colon" :column="column" :content-style="contentStyle"
                  :label-style="labelStyle" :layout="layout" :size="size" >
    <template #extra v-if="'extra' in slots">
      <slot name="extra"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-descriptions>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsDescriptions",
  props: {
    bordered: {
      type: Boolean,
      default: false
    },
    colon: {
      type: Boolean,
      default: true
    },
    column: {
      type: [Number, Object],
      default: 3
    },
    contentStyle: {
      type: Object,
      default: null
    },
    labelStyle: {
      type: Object,
      default: null
    },
    layout: {
      type: String,
      default: 'horizontal'
    },
    size: {
      type: String,
      default: 'default'
    },
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
