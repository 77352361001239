<!--
*@description 
*@author 杜超峰
*@date 2023/04/25 19:45:49
!-->

<template>
    <a-sub-menu class="cgs-design-sub-menu" :key="menuKey" :disabled="disabled" :popupClassName="modeClassMap[typeMode]"
        :popupOffset="popupOffset" :title="title">
        <template #expandIcon v-if="'expandIcon' in slots">
            <slot name="expandIcon"></slot>
        </template>
        <template #icon v-if="'icon' in slots">
            <slot name="icon"></slot>
        </template>
        <template #title v-if="'title' in slots">
            <slot name="title"></slot>
        </template>
        <slot></slot>
    </a-sub-menu>
</template>

<script>
    import {
        defineComponent,
        useSlots
    } from 'vue'

    export default defineComponent({
        name: "cgsSubMenu",
        components: {},
        props: {
            typeMode: {
                type: String,
                default: 'default' //default  portal
            },
            typeSubMode: {
                type: String,
                default: 'default' //default  portal
            },
            menuKey: {
                type: [String, Number],
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            popupClassName: {
                type: String,
                default: undefined
            },
            popupOffset: {
                type: Array,
                default: undefined
            },
            title: {
                type: String,
                default: undefined
            }
        },

        setup(props, ctx) {
            const slots = useSlots()
            let modeClassMap = {
                default: '',
                portal: 'cgs-design-sub-menu'
            }
            const titleClickHandler = (params) => {
                ctx.emit('titleClick', params)
            }
            return {
                slots,
                modeClassMap,
                titleClickHandler
            }
        },
    })
</script>

<style lang='less'>
 @import '~@style/less/theme.less';
    .cgs-design-sub-menu.ant-menu-submenu-popup > .ant-menu{
        .text-2;
        color: @font-color-2;
        text-align: center;
        background: @card-color;
    }
    .cgs-design-sub-menu.ant-menu-light .ant-menu-item:hover{
        color: @primary-color;
        background: @selected-color;
    }
    .cgs-design-sub-menu.ant-menu-light .ant-menu-submenu-title:hover{
        color: @primary-color;
        background: @selected-color;
    }
    .cgs-design-sub-menu .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
        color: @primary-color;
    }
    .cgs-design-sub-menu.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open{
        color: @primary-color;
        background: @selected-color;
    }
    .cgs-design-sub-menu.ant-menu-submenu-open{
        color: @primary-color;
        background: @selected-color;
    }
    // .cgs-design-sub-menu.ant-menu-submenu-open .ant-menu-submenu-arrow{
    //     color: @primary-color;
    // }
</style>