<template>
  <a-carousel :autoplay="autoplay" :arrows="arrows" :dotPosition="dotPosition" :dots="dots" :dotsClass="dotsClass"
              :easing="easing" :effect="effect" :afterChange="afterChange" :beforeChange="beforeChange"
              @goToHandler="goToHandler" @nextHandler="nextHandler" @prevHandler="prevHandler" >
    <template #prevArrow v-if="'prevArrow' in slots">
      <slot name="prevArrow"></slot>
    </template>
    <template #nextArrow v-if="'nextArrow' in slots">
      <slot name="nextArrow"></slot>
    </template>
    <slot></slot>
  </a-carousel>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsCarousel",
  props: {
    autoplay: {
      type: Boolean,
      default: false
    },
    arrows: {
      type: Boolean,
      default: false
    },
    dotPosition: {
      type: String,
      default: 'bottom'
    },
    dots: {
      type: Boolean,
      default: true
    },
    dotsClass: {
      type: String,
      default: 'slick-dots'
    },
    easing: {
      type: String,
      default: 'linear'
    },
    effect: {
      type: String,
      default: 'scrollx'
    },
    afterChange: {
      type: Function,
      default: undefined
    },
    beforeChange: {
      type: Function,
      default: undefined
    }
  },
  setup(props, ctx){
    const slots = useSlots();
    const goToHandler = (slideNumber, dontAnimate) => {
      ctx.emit('goTo',slideNumber, dontAnimate)
    }
    const nextHandler = () => {
      ctx.emit('next')
    }
    const prevHandler = () => {
      ctx.emit('prev')
    }
    return{
      slots,
      goToHandler,
      nextHandler,
      prevHandler,
    }
  }
})
</script>

<style scoped>

</style>
