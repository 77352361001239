<template>
  <a-form-item class="cgs-design-form-item" :autoLink="autoLink" :colon="colon" :hasFeedback="hasFeedback" :htmlFor="htmlFor" :labelAlign="labelAlign"
          :labelCol="labelCol" :name="name" :required="required" :rules="rules" :validateFirst="validateFirst"
          :validateStatus="validateStatus" :validateTrigger="validateTrigger" :wrapperCol="wrapperCol">
    <template #extra v-if="'extra' in slots">
      <slot name="extra"></slot>
    </template>
    <template #help v-if="'help' in slots">
      <slot name="help"></slot>
    </template>
    <template #label v-if="'label' in slots">
      <slot name="label"></slot>
    </template>
    <slot></slot>
  </a-form-item>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsFormItem",
  props: {
    autoLink: {
      type: Boolean,
      default: true
    },
    colon: {
      type: Boolean,
      default: true
    },
    hasFeedback: {
      type: Boolean,
      default: false
    },

    htmlFor: {
      type: String,
      default: undefined
    },
    labelAlign: {
      type: String,
      default: undefined,
    },
    labelCol: {
      type: Object,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [Object, Array],
      default: undefined
    },
    validateFirst: {
      type: Boolean,
      default: false
    },
    validateStatus: {
      type: String,
      default: undefined,
    },
    validateTrigger: {
      type: [String, Array],
      default: "change",
    },
    wrapperCol: {
      type: Object,
      default: undefined
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
