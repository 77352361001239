<template>
  <a-progress :format="format" :percent="percent" :show-info="showInfo" :status="status" :stroke-color="strokeColor"
      :stroke-linecap="strokeLinecap" :success="success" :title="title" :trail-color="trailColor" :type="type"
      :steps="steps" :gap-degree="gapDegree" :gap-position="gapPosition" :stroke-width="strokeWidth" :width="width"/>
</template>

<script>
import {useSlots} from "vue";

export default {
  name: "cgsProgress",
  props: {
    format: {
      type: Function,
      default: undefined
    },
    percent: {
      type: Number,
      default: 0
    },
    showInfo: {
      type: Boolean,
      default: true
    },
    status: {
      type: String, //success exception normal active
      default: undefined
    },
    strokeColor: {
      type: [String, Object],
      default: undefined
    },
    strokeLinecap: {
      type: String,
      default: 'round'
    },
    success: {
      type: Object,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    trailColor: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'line'
    },
    steps: {
      type: Number,
      default: undefined
    },
    // gapDegree: {
    //   type: Number,
    //   default: 75
    // },
    gapPosition: {
      type: String,
      default: 'bottom'
    },
    // strokeWidth: {
    //   type: Number,
    //   default: 10
    // },
    width: {
      type: Number,
      default: 132
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    strokeWidth() {
      if (this.type === 'circle' ||this.type === 'dashboard') {
        return 6;
      } else {
        return 10;
      }
    },
    // eslint-disable-next-line vue/no-dupe-keys
    gapDegree() {
      if(this.type === 'dashboard'){
        return 75;
      }else {
        return undefined;
      }
    },
  }
}
</script>
<style scoped>

</style>
