<template>
  <a-step class="cgs-design-step" :disabled="disabled" :status="status">
    <template #description v-if="'description' in slots">
      <slot name="description"></slot>
    </template>
    <template #icon v-if="'icon' in slots">
      <slot name="icon"></slot>
    </template>
    <template #subTitle v-if="'subTitle' in slots">
      <slot name="subTitle"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-step>
</template>

<script>
import {
  defineComponent,
  useSlots
} from "vue";
export default defineComponent({
  name: "cgsStep",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: 'wait'
    },
  },
  setup(){
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style lang="less">
@import '~@style/less/theme.less';
.cgs-design-step.ant-steps-item-finish {
  & > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
    background: @primary-color;
  }
  .ant-steps-item-icon{
    background: @primary-color !important;
    border-color: @primary-color !important;
    .primary-bg-font-color();
    .anticon svg{
      .primary-bg-svg-color();
    }
  }
  .ant-steps-item-title,
  .ant-steps-item-description,
  .ant-steps-item-subtitle{
    color: @primary-color !important;
  }
}
.cgs-design-step.ant-steps-item-process {
  & > .ant-steps-item-container > .ant-steps-item-icon{
    background: transparent !important;
    border-color: @primary-color !important;
    .ant-steps-icon{
      color: @primary-color;
    }
  }

  .ant-steps-item-title,
  .ant-steps-item-description,
  .ant-steps-item-subtitle{
    color: @primary-color !important;
  }
}
.cgs-design-step.ant-steps-item-wait {
  & > .ant-steps-item-container > .ant-steps-item-icon{
    background: transparent !important;
    border-color: @font-color-1 !important;
    .ant-steps-icon{
      color: @font-color-1;
    }
  }
  .ant-steps-icon,
  .ant-steps-item-title,
  .ant-steps-item-description,
  .ant-steps-item-subtitle{
    color: @font-color-1 !important;
  }
}
</style>
