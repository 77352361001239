<template>
  <a-popconfirm v-bind="attrs">
    <template v-slot:[name] v-for="(_, name) in slots">
      <slot :name='name'></slot>
    </template>
  </a-popconfirm>
</template>

<script>
  import {
    defineComponent,
    useAttrs,
    useSlots
  } from 'vue'

  export default defineComponent({
    name: "cgsPopconfirm",
    props: {},
    setup() {
      const slots = useSlots();
      const attrs = useAttrs()
      return {
        slots,
        attrs
      }
    }
  })
</script>

<style scoped>

</style>