<template>
  <a-comment>
    <template #actions v-if="'actions' in slots">
      <slot name="actions"></slot>
    </template>
    <template #author v-if="'author' in slots">
      <slot name="author"></slot>
    </template>
    <template #avatar v-if="'avatar' in slots">
      <slot name="avatar"></slot>
    </template>
    <template #content v-if="'content' in slots">
      <slot name="content"></slot>
    </template>
    <template #datetime v-if="'datetime' in slots">
      <slot name="datetime"></slot>
    </template>
    <slot></slot>
  </a-comment>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsComment",
  props: {
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
