<!--
*@description
*@author 杜超峰
*@date 2023/04/19 17:53:46
!-->

<template>
    <a-button class="cgs-design-button" :type="type" :size="size" :shape="shape" :loading="loading" :href="href"
        :ghost="ghost" :disabled="disabled" :danger="danger" :block="block">
        <slot></slot>
        <template #icon v-if="'icon' in slots">
            <slot name="icon"></slot>
        </template>
    </a-button>
</template>

<script>
    import {
        defineComponent,
        useSlots
    } from 'vue'

    export default defineComponent({
        name: "cgsButton",
        props: {
            type: {
                type: String,
                default: 'default'
            },
            size: {
                type: String,
                default: 'middle'
            },
            shape: {
                type: String,
                default: 'default'
            },
            loading: {
                type: [Boolean, Number],
                default: false
            },
            href: {
                type: [String, undefined],
                default: undefined
            },
            ghost: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            danger: {
                type: Boolean,
                default: false
            },
            block: {
                type: [Boolean, undefined],
                default: undefined
            }
        },

        setup() {
            const slots = useSlots();
            return {
                slots
            }
        },
    })
</script>

<style lang="less" scoped>
    @import '~@style/less/theme.less';
    .cgs-design-button.ant-btn.ant-btn-background-ghost.ant-btn-primary.ant-btn-dangerous{
        color: @error-color;
        border-color: @error-color;
        text-shadow: none;
        background-color: transparent;
    }
    .cgs-design-button.ant-btn.ant-btn-dangerous.ant-btn-link{
        color: @error-color;
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;
    }
    .cgs-design-button.ant-btn.ant-btn-dangerous.ant-btn-primary{
        border-color: @error-color;
        background-color: @error-color;
    }
    .cgs-design-button.ant-btn.ant-btn-dangerous.ant-btn-primary:hover{
        color: #ffffff;
        border-color: #f5222d;
        background-color: #f5222d;
    }
    .cgs-design-button.ant-btn.ant-btn-dangerous{
        color: @error-color;
        border-color: @error-color;
        background-color: transparent;
    }
    // @test-color: #ff0000;
    .cgs-design-button.ant-btn.ant-btn-text {
        border-radius: 2px;
        background: transparent;
        border-color: transparent;
    }

    .cgs-design-button.ant-btn.ant-btn-text:hover,
    .cgs-design-button.ant-btn.ant-btn-text:focus {
        background: @selected-color;
    }

    .cgs-design-button.ant-btn.ant-btn-primary {
        background: @primary-color;
        border-color: @primary-color;
        .primary-bg-font-color()
    }

    .cgs-design-button.ant-btn.ant-btn-primary:hover,
    .cgs-design-button.ant-btn.ant-btn-primary:focus {
        background: @primary-color-08;
        border-color: @primary-color-08;
    }

    .cgs-design-button.ant-btn-background-ghost.ant-btn-primary {
        color: @primary-color;
        border-color: @primary-color;
    }

    .cgs-design-button.ant-btn.ant-btn-background-ghost {
        background: transparent;
    }

    .cgs-design-button.ant-btn-background-ghost.ant-btn-primary:hover,
    .cgs-design-button.ant-btn-background-ghost.ant-btn-primary:focus {
        color: @primary-color-08;
        border-color: @primary-color-08;
    }

    .cgs-design-button.ant-btn {
        border-radius: 5px;
        background: @selected-color;
        border-color: @selected-color;
        .font-color-1();
        .text-2();
    }

    .cgs-design-button.ant-btn:hover,
    .cgs-design-button.ant-btn:focus,
    .cgs-design-button.ant-btn:active {
        background: @selected-color-08;
        border-color: @selected-color-08;
    }

    .cgs-design-button.ant-btn:hover,
    .cgs-design-button.ant-btn:focus {}

    .cgs-design-button.ant-btn-circle {
        border-radius: 50%;
    }

    .cgs-design-button.ant-btn.ant-btn-background-ghost:hover,
    .cgs-design-button.ant-btn.ant-btn-background-ghost:focus,
    .cgs-design-button.ant-btn.ant-btn-background-ghost:active {
        color: @font-color-1;
        background: @selected-color-08;
        border-color: @selected-color-08;
    }

    button[ant-click-animating-without-extra-node].cgs-design-button:after {
        border: 0 none;
        opacity: 0;
        animation: none 0 ease 0 1 normal;
        outline: none;
        &:active {
            outline: 0;
        }
    }
</style>