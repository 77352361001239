import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@style/less/global-component.less'
import {
    message
} from 'ant-design-vue';

import Affix from '@components/affix/index.vue'
import Alert from '@components/alert/index.vue'
import Anchor from '@components/anchor/index.vue'
import AnchorLink from '@components/anchorLink/index.vue'
import AutoComplete from '@components/autoComplete/index.vue'
import Avatar from '@components/avatar/index.vue'
import AvatarGroup from '@components/avatarGroup/index.vue'
import BackTop from '@components/backTop/index.vue'
import Badge from '@components/badge/index.vue'
import BadgeRibbon from '@components/badgeRibbon/index.vue'
import Breadcrumb from '@components/breadcrumb/index.vue'
import BreadcrumbItem from '@components/breadcrumbItem/index.vue'
import Button from '@components/button/index.vue'
import Calendar from '@components/calendar/index.vue'
import Card from '@components/card/index.vue'
import Carousel from '@components/carousel/index.vue'
import Cascader from '@components/cascader/index.vue'
import Checkbox from '@components/checkbox/index.vue'
import CheckboxGroup from '@components/checkboxGroup/index.vue'
import Col from '@components/col/index.vue'
import Collapse from '@components/collapse/index.vue'
import CollapsePanel from '@components/collapsePanel/index.vue'
import ColorPicker from '@components/colorPicker/index.vue'
import Comment from '@components/comment/index.vue'
import ConfigProvider from '@components/configProvider/index.vue'
import DatePicker from '@components/datePicker/index.vue'
import Descriptions from '@components/descriptions/index.vue'
import DescriptionsItem from '@components/descriptionsItem/index.vue'
import Divider from '@components/divider/index.vue'
import Drawer from '@components/drawer/index.vue'
import DropDown from '@components/dropDown/index.vue'
import Empty from '@components/empty/index.vue'
import Form from '@components/form/index.vue'
import FormItem from '@components/formItem/index.vue'
import Icon from '@components/icon/index.vue'
import Image from '@components/image/index.vue'
import Input from '@components/input/index.vue'
import InputNumber from '@components/inputNumber/index.vue'
import Layout from '@components/layout/index.vue'
import List from '@components/list/index.vue'
import ListItem from '@components/listItem/index.vue'
import ListItemMeta from '@components/listItemMeta/index.vue'
import Mentions from '@components/mentions/index.vue'
import MentionsOption from '@components/mentionsOption/index.vue'
import Menu from '@components/menu/index.vue'
import MenuItem from '@components/menuItem/index.vue'
import MenuItemGroup from '@components/menuItemGroup/index.vue'
import Message from '@components/message/index.vue'
import Modal from '@components/modal/index.vue'
import ModalInner from '@components/modalInner/index.vue'
import More from '@components/more/index.vue'
import Notification from '@components/notification/index.vue'
import PageHeader from '@components/pageHeader/index.vue'
import Pagination from '@components/pagination/index.vue'
import Password from '@components/password/index.vue'
import Popconfirm from '@components/popconfirm/index.vue'
import Popover from '@components/popover/index.vue'
import Progress from '@components/progress/index.vue'
import Radio from '@components/radio/index.vue'
import RadioButton from '@components/radioButton/index.vue'
import RadioGroup from '@components/radioGroup/index.vue'
import RangePicker from '@components/rangePicker/index.vue'
import Rate from '@components/rate/index.vue'
import Result from '@components/result/index.vue'
import Row from '@components/row/index.vue'
import Select from '@components/select/index.vue'
import SelectOption from '@components/selectOption/index.vue'
import Skelecton from '@components/skelecton/index.vue'
import SkeletonAvatarProps from '@components/skeletonAvatarProps/index.vue'
import Slider from '@components/slider/index.vue'
import Space from '@components/space/index.vue'
import Spin from '@components/spin/index.vue'
import Statistic from '@components/statistic/index.vue'
import StatisticCountdown from '@components/statisticCountdown/index.vue'
import Steps from '@components/steps/index.vue'
import StepsStep from '@components/stepsStep/index.vue'
import SubMenu from '@components/subMenu/index.vue'
import Switch from '@components/switch/index.vue'
import Table from '@components/table/index.vue'
import TabPane from '@components/tabPane/index.vue'
import Tabs from '@components/tabs/index.vue'
import Tag from '@components/tag/index.vue'
import TextArea from '@components/textArea/index.vue'
import Timeline from '@components/timeline/index.vue'
import TimelineItem from '@components/timelineItem/index.vue'
import TimePicker from '@components/timePicker/index.vue'
import TimeRangePicker from '@components/timeRangePicker/index.vue'
import Tooltip from '@components/tooltip/index.vue'
import Transfer from '@components/transfer/index.vue'
import Tree from '@components/tree/index.vue'
import TreeNode from '@components/treeNode/index.vue'
import TreeSelect from '@components/treeSelect/index.vue'
import TreeSelectNode from '@components/treeSelectNode/index.vue'
import Upload from '@components/upload/index.vue'
import UploadDragger from '@components/uploadDragger/index.vue'
import SelectThemeIcon from '@components/selectThemeIcon/index.vue'
import '@components/selectThemeIcon/logoIcon/iconfont.css'
import * as selectThemeIconConfig from '@components/selectThemeIcon/config'



import plugins from "@/plugins/index"
import './init.js'

const components = [
    Affix,
    Alert,
    Anchor,
    AnchorLink,
    AutoComplete,
    Avatar,
    AvatarGroup,
    BackTop,
    Badge,
    BadgeRibbon,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Calendar,
    Card,
    Carousel,
    Cascader,
    Checkbox,
    CheckboxGroup,
    Col,
    Collapse,
    CollapsePanel,
    ColorPicker,
    Comment,
    ConfigProvider,
    DatePicker,
    Descriptions,
    DescriptionsItem,
    Divider,
    Drawer,
    DropDown,
    Empty,
    Form,
    FormItem,
    Icon,
    Image,
    Input,
    InputNumber,
    Layout,
    List,
    ListItem,
    ListItemMeta,
    Mentions,
    MentionsOption,
    Menu,
    MenuItem,
    MenuItemGroup,
    Message,
    Modal,
    ModalInner,
    More,
    Notification,
    PageHeader,
    Pagination,
    Password,
    Popconfirm,
    Popover,
    Progress,
    Radio,
    RadioButton,
    RadioGroup,
    RangePicker,
    Rate,
    Result,
    Row,
    Select,
    SelectOption,
    Skelecton,
    SkeletonAvatarProps,
    Slider,
    Space,
    Spin,
    Statistic,
    StatisticCountdown,
    Steps,
    StepsStep,
    SubMenu,
    Switch,
    Table,
    TabPane,
    Tabs,
    Tag,
    TextArea,
    Timeline,
    TimelineItem,
    TimePicker,
    TimeRangePicker,
    Tooltip,
    Transfer,
    Tree,
    TreeNode,
    TreeSelect,
    TreeSelectNode,
    Upload,
    UploadDragger,
    SelectThemeIcon
]

components.forEach(component => {
    component.install = function (Vue) {
        Vue.component(component.name, component)
    }
})

const install = (Vue) => {
    components.forEach(component => {
        Vue.component(component.name, component)
    })
    Vue.use(Antd)
    plugins.install(Vue)
}

if (typeof window !== 'undefined' && window.Vue) {
    /* script方式引入时主动调用install方法！！ */
    install(window.Vue);
}

export default {
    install,
    ...components
    // cgsMessage: message
}

export const cgsMessage = message
export const cgsThemeIconConfig = selectThemeIconConfig