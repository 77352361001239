<template>
  <a-steps class="cgs-design-steps" v-model:current="showCurrent" :direction="direction" :initial="initial" :labelPlacement="labelPlacement"
           :percent="percent" :responsive="responsive" :size="size" :status="status" :type="type" @changeHandler="changeHandler">
    <template #progressDot v-if="'progressDot' in slots">
      <slot name="progressDot"></slot>
    </template>
    <slot></slot>
  </a-steps>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from "vue";
export default defineComponent({
  name: "cgsSteps",
  props: {
    current: {
      type: Number,
      default: 0
    },
    direction: {
      type: String,
      default: 'horizontal'
    },
    initial: {
      type: Number,
      default: 0
    },
    labelPlacement: {
      type: String,
      default: 'horizontal'
    },
    percent: {
      type: Number,
      default: undefined
    },
    responsive: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'default'
    },
    status: {
      type: String,
      default: 'process'
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  setup(props, ctx){
    const slots = useSlots();
    const changeHandler = (change) => {
      ctx.emit('change', change)
    }
    const showCurrent = computed({
      get: () => props.current,
      set: (val) => {
        ctx.emit('update:current', val)
      }
    })
    return{
      slots,
      changeHandler,
      showCurrent
    }
  }
})
</script>

<style lang="less">
@import '~@style/less/theme.less';
.cgs-design-steps.ant-steps-navigation .ant-steps-item::before{
  background: @primary-color;
}

.cgs-design-steps.ant-steps-navigation .ant-steps-item.ant-steps-item-finish::after{
  border-color: @primary-color;
}
</style>
