<template>
  <a-checkbox class="cgs-design-checkbox" v-model:checked="showChecked" :autofocus="autofocus" :disabled="disabled"
    :indeterminate="indeterminate" :value="value" @changeHandler="changeHandler">
    <slot></slot>
  </a-checkbox>
</template>

<script>
  import {
    computed,
    defineComponent,
    useSlots
  } from "vue";
  export default defineComponent({
    name: "cgsCheckbox",
    props: {
      autofocus: {
        type: Boolean,
        default: false
      },
      checked: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      indeterminate: {
        type: Boolean,
        default: false
      },
      value: {
        type: [Boolean, String, Number],
        default: undefined
      }
    },
    setup(props, ctx) {
      const slots = useSlots();
      const changeHandler = (e) => {
        ctx.emit('change', e)
      }
      const showChecked = computed({
        get: () => props.checked,
        set: (val) => {
          ctx.emit('update:checked', val)
        }
      })
      return {
        slots,
        changeHandler,
        showChecked
      }
    }
  })
</script>

<style lang="less">
  @import '~@style/less/theme.less';
  .cgs-design-checkbox.ant-checkbox-wrapper{
    color: @font-color-1;
  }

  .cgs-design-checkbox .ant-checkbox-inner {
    background: transparent;
    .border();
  }

  .cgs-design-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .cgs-design-checkbox .ant-checkbox:hover .ant-checkbox-inner,
  .cgs-design-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: @primary-color;
  }

  .cgs-design-checkbox .ant-checkbox-checked .ant-checkbox-inner{
    background: @primary-color;
    border-color: @primary-color;
  }
  .cgs-design-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner::after{
    background-color: @primary-color;
  }
</style>