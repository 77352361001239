<template>
  <a-dropdown overlayClassName="cgs-design-dropdown" v-model:visible="showVisible"
    :destroyPopupOnHide="destroyPopupOnHide" :disabled="disabled" :getPopupContainer="getPopupContainer"
    :overlayStyle="overlayStyle" :placement="placement" :trigger="trigger" @visibleChange="visibleChangeHandler">
    <template #overlay v-if="'overlay' in slots">
      <slot name="overlay"></slot>
    </template>
    <slot></slot>
  </a-dropdown>
</template>

<script>
  import {
    computed,
    defineComponent,
    useSlots
  } from 'vue'

  export default defineComponent({
    name: "cgsDropdown",
    props: {
      visible: {
        type: Boolean,
        default: undefined
      },
      destroyPopupOnHide: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: undefined
      },
      getPopupContainer: {
        type: Function,
        default: () => document.body
      },
      overlayClassName: {
        type: String,
        default: undefined
      },
      overlayStyle: {
        type: Object,
        default: undefined
      },
      placement: {
        type: String,
        default: 'bottomLeft'
      },
      trigger: {
        type: Array,
        default: () => ['hover']
      },

    },
    setup(props, ctx) {
      const slots = useSlots();
      const visibleChangeHandler = (visible) => {
        ctx.emit('visibleChange', visible)
      }
      const showVisible = computed({
        get: () => props.visible,
        set: (val) => {
          ctx.emit('update:visible', val)
        }
      })
      return {
        slots,
        visibleChangeHandler,
        showVisible
      }
    }
  })
</script>

<style lang='less'>
  @import '~@style/less/theme.less';
  .cgs-design-dropdown {
    .border()
  }
  .cgs-design-dropdown .ant-dropdown-menu-submenu-popup {
    .border()
  }

  .cgs-design-dropdown .ant-dropdown-menu {
    background: @card-color;
    .text-2;
    // text-align: center;
    color: @font-color-2;
    box-shadow: 0 0 0 0 @card-color;
  }

  .cgs-design-dropdown .ant-dropdown-menu-item,
  .cgs-design-dropdown .ant-dropdown-menu-submenu-title {
    color: @font-color-2;
    .text-2;
    // text-align: center;
  }

  .cgs-design-dropdown .ant-dropdown-menu-vertical {
    border-right: 0px solid @card-color;
  }

  .cgs-design-dropdown .ant-dropdown-menu-light .ant-dropdown-menu-item:hover {
    color: @primary-color;
    background: @selected-color;
  }

  .cgs-design-dropdown .ant-dropdown-menu-item:active,
  .ant-dropdown-menu-submenu-title:active {
    background: @primary-color;
  }

  .cgs-design-dropdown .ant-dropdown-menu-light .ant-dropdown-menu-submenu-title:hover {
    color: @primary-color;
    background: @selected-color;
  }

  .cgs-design-dropdown .ant-dropdown-menu-submenu:hover>.ant-dropdown-menu-submenu-title>.ant-dropdown-menu-submenu-arrow {
    color: @primary-color;
  }

  .cgs-design-dropdown .ant-dropdown-menu-light .ant-dropdown-menu-submenu-active {
    color: @primary-color;
    background: @selected-color;
  }

  .cgs-design-dropdown .ant-dropdown-menu-light .ant-dropdown-menu-submenu-active .ant-dropdown-menu-submenu-arrow {
    color: @primary-color;
  }
</style>