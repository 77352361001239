<template>
  <a-cascader :allowClear="allowClear" :autofocus="autofocus" :bordered="bordered" :changeOnSelect="changeOnSelect"
              :defaultValue="defaultValue" :disabled="disabled" :displayRender="displayRender" :dropdownClassName="dropdownClassName"
              :dropdownStyle="dropdownStyle" :expandTrigger="expandTrigger" :fieldNames="fieldNames" 
              :getPopupContainer="getPopupContainer" :loadData="loadData" :maxTagCount="maxTagCount" :multiple="multiple" 
              :open="open" :options="options" :placeholder="placeholder" :placement="placement" 
              :searchValue="searchValue" :showSearch="showSearch" :size="size" v-model:value="showValue"
              @blurHandler="blurHandler" @changeHandler="changeHandler" @dropdownVisibleChangeHandler="dropdownVisibleChangeHandler"
              @focusHandler="focusHandler" @searchHandler="searchHandler">
    <template #clearIcon v-if="'clearIcon' in slots">
      <slot name="clearIcon"></slot>
    </template>
    <template #expandIcon v-if="'expandIcon' in slots">
      <slot name="expandIcon"></slot>
    </template>
    <template #maxTagPlaceholder v-if="'maxTagPlaceholder' in slots">
      <slot name="maxTagPlaceholder"></slot>
    </template>
    <template #notFoundContent v-if="'notFoundContent' in slots">
      <slot name="notFoundContent"></slot>
    </template>
    <template #removeIcon v-if="'removeIcon' in slots">
      <slot name="removeIcon"></slot>
    </template>
    <template #suffixIcon v-if="'suffixIcon' in slots">
      <slot name="suffixIcon"></slot>
    </template>
    <template #tagRender v-if="'tagRender' in slots">
      <slot name="tagRender"></slot>
    </template>

  </a-cascader>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from "vue";
export default defineComponent({
  name: "cgsCascader",
  props: {
    allowClear: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: true
    },
    changeOnSelect: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: [Array, Number],
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayRender: {
      type: Function,
      default: undefined
    },
    dropdownClassName: {
      type: String,
      default: undefined
    },
    dropdownStyle: {
      type: Object,
      default: () => ({})
    },
    expandTrigger: {
      type: String,
      default: 'click',
      validator: value => ['click', 'hover'].includes(value)
    },
    fieldNames: {
      type: Object,
      default: () => ({ label: 'label', value: 'value', children: 'children' })
    },
    getPopupContainer: {
      type: Function,
      default: () => document.body
    },
    loadData: {
      type: Function,
      default: undefined
    },
    maxTagCount: {
      type: [Number, Object],
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: undefined
    },
    open: {
      type: Boolean,
      default: undefined
    },
    options: {
      type: Array,
      default: undefined
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    placement: {
      type: String,
      default: 'bottomLeft',
      validator: value => ['bottomLeft', 'bottomRight', 'topLeft', 'topRight'].includes(value)
    },
    searchValue: {
      type: String,
      default: undefined
    },
    showSearch: {
      type: [Boolean, Object],
      default: false
    },
    size: {
      type: String, //large | default | small
      default: 'default',
    },
    value: {
      type: Array,
      default: undefined
    }
  },

  setup(props, ctx){
    const slots = useSlots();
    const blurHandler = () => {
      ctx.emit('blur')
    }
    const changeHandler = (value, selectedOptions) => {
      ctx.emit('change', value, selectedOptions)
    }
    const dropdownVisibleChangeHandler = (value) => {
      ctx.emit('dropdownVisibleChange', value)
    }
    const focusHandler = () => {
      ctx.emit('focus')
    }
    const searchHandler = (value) => {
      ctx.emit('search', value)
    }
    const showValue = computed({
      get: () => props.value,
      set: (val) => {
        ctx.emit('update:value', val)
      }
      
    })

    return{
      slots,
      blurHandler,
      changeHandler,
      dropdownVisibleChangeHandler,
      focusHandler,
      searchHandler,
      showValue,
  
    }
  }
})
</script>

<style scoped>

</style>
