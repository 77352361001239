<template>
  <a-list-item>
    <template #actions v-if="'actions' in slots">
      <slot name="actions"></slot>
    </template>
    <template #extra v-if="'extra' in slots">
      <slot name="extra"></slot>
    </template>
    <slot></slot>
  </a-list-item>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsListItem",
  props: {
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
