<template>
  <a-alert :afterClose="afterClose" :banner="banner" :closable="closable" :showIcon="showIcon" :type="type" @closeHandler="closeHandler">
    <template #closeIcon v-if="'closeIcon' in slots">
      <slot name="closeIcon"></slot>
    </template>
    <template #closeText v-if="'closeText' in slots">
      <slot name="closeText"></slot>
    </template>
    <template #description v-if="'description' in slots">
      <slot name="description"></slot>
    </template>
    <template #icon v-if="'icon' in slots">
      <slot name="icon"></slot>
    </template>
    <template #message v-if="'message' in slots">
      <slot name="message"></slot>
    </template>
    <slot></slot>
  </a-alert>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsAlert",
  props: {
    afterClose: {
      type: Function,
      default: () => {},
    },
    banner: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: undefined
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'info',
    },
  },
  setup(props, ctx){
    const slots = useSlots();
    const closeHandler = (e) => {
      ctx.emit('close', e)
    }
    return{
      slots,
      closeHandler
    }
  }
})
</script>

<style scoped>

</style>
