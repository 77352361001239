<template>
    <ColorPicker class="cgs-design-colorpicker" :isWidget="isWidget" :format="format" :shape="shape" :pureColor="pureColor"
    :gradientColor="gradientColor" :pickerType="pickerType" :useType="useType" :disableHistory="disableHistory"
    :roundHistory="roundHistory" :disableAlpha="disableAlpha" :zIndex="zIndex" :lang="lang"
    @update:pureColor="updatePureColorHandler" @pureColorChange="pureColorChangeHandler"
    @update:gradientColor="updateGradientColorHandler" @gradientColorChange="gradientColorChangeHandler"
    @update:activeKey="updateActiveKeyHandler" @activeKeyChange="activeKeyChangeHandler" />
</template>

<script>
import {
    defineComponent,
    useSlots
} from 'vue'
// import { DownOutlined } from '@ant-design/icons-vue';
import {ColorPicker}  from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

export default defineComponent({
    name: "cgsColorPicker",
    components: {
        // DownOutlined
        ColorPicker 
    },
    props: {
        isWidget: {
            type: Boolean,
            default: false,
        },
        format: {
            type: String,
            default: 'hex',
        },
        shape: {
            type: String,
            default: 'square',
        },
        pureColor: {
            type: String,
            default: undefined,
        },
        gradientColor: {
            type: String,
            default: 'linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)',
        },
        pickerType: {
            type: String,
            default: 'fk',
        },
        useType: {
            type: String,
            default: 'pure',
        },
        disableHistory: {
            type: Boolean,
            default: true,
        },
        roundHistory: {
            type: Boolean,
            default: true,
        },
        disableAlpha: {
            type: Boolean,
            default: true,
        },
        zIndex: {
            type: Number,
            default: 0
        },
        lang: {
            type: String,
            default: 'ZH-cn',
        },
    },
    setup(props, ctx) {
        const slots = useSlots();
        const updatePureColorHandler = (val) => {
            ctx.emit('update:pureColor', val)
        }
        const pureColorChangeHandler = (val) => {
            ctx.emit('pureColorChange', val)
        }
        const updateGradientColorHandler = (val) => {
            ctx.emit('update:gradientColor', val)
        }
        const gradientColorChangeHandler = (val) => {
            ctx.emit('gradientColorChange', val)
        }
        const updateActiveKeyHandler = (val) => {
            ctx.emit('update:activeKey', val)
        }
        const activeKeyChangeHandler = (val) => {
            ctx.emit('activeKeyChange', val)
        }
        return {
            slots,
            updatePureColorHandler,
            pureColorChangeHandler,
            updateGradientColorHandler,
            gradientColorChangeHandler,
            updateActiveKeyHandler,
            activeKeyChangeHandler,
        }
    }
})

</script>

<style lang="less">
@import '~@style/less/theme.less';
// #cgs-design-colorpicker{
//     width: 32px;
//     height: 32px;
//     position: relative;
//     .border()
// }
// #cgs-design-colorpicker .vc-color-wrap {
//     width: 32px;
//     height: 32px;
//     border-radius: 5px;
// }
// .cgs-design-colorpicker-icon{
//     position: absolute;
//     top: calc(50% - 7px);
//     left: calc(50% - 7px);
//     z-index: 5;
//     pointer-events: none;
// }
.vc-colorpicker{
        background:var(--cgs-card-color) !important;
        .vc-chrome-colorPicker{
            background: var(--cgs-card-color) !important;
        }
        .vc-chrome-colorPicker-body{
            background: var(--cgs-card-color) !important;
        }
        .vc-color-input{
            input{
                background: @selected-color !important;
                color:  @font-color-1 !important;
            }
        }
    }
</style>