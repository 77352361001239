<template>
  <div class="cgs-design-dataPicker-container" ref="container">
    <a-date-picker class="cgs-design-datePicker" :style="{width: '100%'}" v-bind="attrs" :dropdownClassName="dropdownClassName"
      :getPopupContainer="getContainer">

      <template v-for="(_, name) in slots" #[name]="scopedData">
        <slot :name='name' v-bind="scopedData"></slot>
      </template>
    </a-date-picker>
  </div>
</template>

<script>
  import {
    ref,
    defineComponent,
    useAttrs,
    useSlots
  } from "vue";
  export default defineComponent({
    name: "cgsDatePicker",
    props: {
      dropdownClassName: {
        type: String,
        default: 'cgs-design-datePicker-dropdown'
      },
      isAppendBody: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const slots = useSlots();
      const attrs = useAttrs();
      const container = ref(null);
      const getContainer = () => container.value
      return {
        slots,
        attrs,
        container,
        getContainer: props.isAppendBody ? null : getContainer
      }
    },
  })
</script>

<style lang="less">
  @import '~@style/less/theme.less';

  .cgs-design-dataPicker-container,
  .cgs-design-datePicker {
    width: 100%;
  }

  .cgs-design-datePicker.ant-picker-focused {
    border-color: @font-color-1;
    .card-box-shadow()
  }

  .cgs-design-datePicker.ant-picker:hover,
  .ant-picker-focused {
    border-color: @font-color-1;
  }

  .cgs-design-datePicker.ant-picker {
    background: @selected-color;
  }

  .cgs-design-datePicker .ant-picker-input>input {
    .text-2;
    color: @font-color-1;
  }

  .cgs-design-datePicker .ant-picker-clear {
    background: rgba(0, 0, 0, 0);
  }

  .cgs-design-datePicker-dropdown.ant-picker-dropdown {
    .text-2;
    color: @font-color-2;
  }

  .cgs-design-datePicker-dropdown .ant-picker-panel-container {
    background: @card-color;
    .card-box-shadow()
  }

  .cgs-design-datePicker-dropdown .ant-picker-panel {
    .border(1px, 1px, 1px, 1px)
  }

  .cgs-design-datePicker-dropdown .ant-picker-header {
    .border(0, 0, 1px, 0)
  }

  .cgs-design-datePicker-dropdown .ant-picker-panel .ant-picker-footer {
    .border(1px, 0, 1px, 0)
  }

  .cgs-design-datePicker-dropdown .ant-picker-header button {
    color: @font-color-1;
  }

  .cgs-design-datePicker-dropdown .ant-picker-header button:hover {
    color: @primary-color;
  }

  .cgs-design-datePicker-dropdown .ant-picker-cell.ant-picker-cell-in-view {
    color: @font-color-2;
  }

  .cgs-design-datePicker-dropdown .ant-picker-cell {
    color: @font-color-3;
  }

  .cgs-design-datePicker-dropdown .ant-picker-content th {
    color: @font-color-1;
  }

  .cgs-design-datePicker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: @primary-color;
  }

  .cgs-design-datePicker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: @font-color-2;
    background: @primary-color;
  }

  .cgs-design-datePicker-dropdown .ant-picker-today-btn,
  .cgs-design-datePicker-dropdown .ant-picker-today-btn:hover {
    color: @primary-color;
  }

  .cgs-design-datePicker-dropdown .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: @selected-color;
  }

  // .cgs-design-datePicker-dropdown .ant-picker-cell-inner:hover{
  //   background: @font-color-3;
  // }
  .cgs-design-datePicker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    color: @font-color-2;
  }

  .cgs-design-datePicker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    color: @font-color-1;
    background: @selected-color;
  }

  .cgs-design-datePicker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: @primary-color;
  }

  .cgs-design-datePicker-dropdown .ant-picker-header {
    color: @font-color-1;
  }

  .cgs-design-datePicker-dropdown a,
  .cgs-design-datePicker-dropdown a:active,
  .cgs-design-datePicker-dropdown a:hover {
    color: @primary-color;
  }

  .cgs-design-datePicker-dropdown .ant-btn-primary {
    color: @font-color-2;
    background: @primary-color;
  }

  .cgs-design-datePicker-dropdown .ant-btn-primary:hover,
  .cgs-design-datePicker-dropdown .ant-btn-primary:focus {
    color: @font-color-1;
    background: @primary-color;
  }
</style>