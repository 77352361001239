<template>
  <a-image :alt="alt" :fallback="fallback" :height="height" :preview="preview" :src="src" :width="width" @errorHandler="errorHandler">
    <template #placeholder v-if="'placeholder' in slots">
      <slot name="placeholder"></slot>
    </template>
    <template #previewMask v-if="'previewMask' in slots">
      <slot name="previewMask"></slot>
    </template>
  </a-image>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsImage",
  props: {
    alt: {
      type: String,
      default: undefined
    },
    fallback: {
      type: String,
      default: undefined
    },
    height: {
      type: [String, Number],
      default: undefined
    },
    preview: {
      type: [Boolean, Object],
      default: true
    },
    src: {
      type: String,
      default: undefined
    },
    width: {
      type: [String, Number],
      default: undefined
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const errorHandler = (event) => {
      ctx.emit('error', event)
    }
    return{
      slots,
      errorHandler
    }
  }
})
</script>

<style scoped>

</style>
