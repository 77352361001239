import logoIconJson from "./logoIcon/iconfont.json"

export const logoIconList = logoIconJson.glyphs

export const profix = logoIconJson.css_prefix_text

export const font_family = logoIconJson.font_family

export const defaultIcon_app = 'yingyongzhongxin'

export const defaultTheme = 'defaultTheme'

export const defaultIcon_worksheet = 'tubiaozhizuomoban-90'

export const themeAllList = [{
  name: '颜色1',
  color: { r: 255, g: 185, b: 0, a: 1 },
  className: 'theme1'
},
{
  name: '颜色2',
  color: { r: 255, g: 140, b: 0, a: 1 },
  className: 'theme2'
},
{
  name: '颜色3',
  color: { r: 247, g: 99, b: 12, a: 1 },
  className: 'theme3'
},
{
  name: '颜色4',
  color: { r: 202, g: 80, b: 16, a: 1 },
  className: 'theme4'
},
{
  name: '颜色5',
  color: { r: 218, g: 59, b: 1, a: 1 },
  className: 'theme5'
},
{
  name: '颜色6',
  color: { r: 239, g: 105, b: 80, a: 1 },
  className: 'theme6'
},
{
  name: '颜色7',
  color: { r: 209, g: 52, b: 56, a: 1 },
  className: 'theme7'
},
{
  name: '颜色8',
  color: { r: 255, g: 67, b: 67, a: 1 },
  className: 'theme8'
},
{
  name: '颜色9',
  color: { r: 231, g: 72, b: 86, a: 1 },
  className: 'theme9'
},
{
  name: '颜色10',
  color: { r: 232, g: 17, b: 35, a: 1 },
  className: 'theme10'
},
{
  name: '颜色11',
  color: { r: 234, g: 0, b: 94, a: 1 },
  className: 'theme11'
},
{
  name: '颜色12',
  color: { r: 195, g: 0, b: 82, a: 1 },
  className: 'theme12'
},
{
  name: '颜色13',
  color: { r: 227, g: 0, b: 140, a: 1 },
  className: 'theme13'
},
{
  name: '颜色14',
  color: { r: 191, g: 0, b: 119, a: 1 },
  className: 'theme14'
},
{
  name: '颜色15',
  color: { r: 194, g: 57, b: 179, a: 1 },
  className: 'theme15'
},
{
  name: '颜色16',
  color: { r: 154, g: 0, b: 137, a: 1 },
  className: 'theme16'
},
{
  name: '颜色17',
  color: { r: 0, g: 120, b: 215, a: 1 },
  className: 'theme17'
},
{
  name: '颜色18',
  color: { r: 0, g: 99, b: 177, a: 1 },
  className: 'theme18'
},
{
  name: '颜色19',
  color: { r: 142, g: 140, b: 216, a: 1 },
  className: 'theme19'
},
{
  name: '颜色20',
  color: { r: 107, g: 105, b: 214, a: 1 },
  className: 'theme20'
},
{
  name: '颜色21',
  color: { r: 135, g: 100, b: 184, a: 1 },
  className: 'theme21'
},
{
  name: '颜色22',
  color: { r: 116, g: 77, b: 169, a: 1 },
  className: 'theme22'
},
{
  name: '颜色23',
  color: { r: 177, g: 70, b: 194, a: 1 },
  className: 'theme23'
},
{
  name: '颜色24',
  color: { r: 136, g: 23, b: 152, a: 1 },
  className: 'theme24'
},
{
  name: '颜色25',
  color: { r: 35, g: 197, b: 208, a: 1 },
  className: 'defaultTheme'
},
{
  name: '颜色26',
  color: { r: 45, g: 125, b: 154, a: 1 },
  className: 'theme26'
},
{
  name: '颜色27',
  color: { r: 0, g: 183, b: 195, a: 1 },
  className: 'theme27'
},
{
  name: '颜色28',
  color: { r: 3, g: 131, b: 135, a: 1 },
  className: 'theme28'
},
{
  name: '颜色29',
  color: { r: 0, g: 178, b: 148, a: 1 },
  className: 'theme29'
},
{
  name: '颜色30',
  color: { r: 1, g: 133, b: 116, a: 1 },
  className: 'theme30'
},
{
  name: '颜色31',
  color: { r: 0, g: 204, b: 106, a: 1 },
  className: 'theme31'
},
{
  name: '颜色32',
  color: { r: 16, g: 137, b: 62, a: 1 },
  className: 'theme32'
},
{
  name: '颜色33',
  color: { r: 122, g: 117, b: 116, a: 1 },
  className: 'theme33'
},
{
  name: '颜色34',
  color: { r: 93, g: 90, b: 88, a: 1 },
  className: 'theme34'
},
{
  name: '颜色35',
  color: { r: 104, g: 118, b: 138, a: 1 },
  className: 'theme35'
},
{
  name: '颜色36',
  color: { r: 81, g: 92, b: 107, a: 1 },
  className: 'theme36'
},
{
  name: '颜色37',
  color: { r: 86, g: 124, b: 115, a: 1 },
  className: 'theme37'
},
{
  name: '颜色38',
  color: { r: 72, g: 104, b: 96, a: 1 },
  className: 'theme38'
},
{
  name: '颜色39',
  color: { r: 73, g: 130, b: 5, a: 1 },
  className: 'theme39'
},
{
  name: '颜色40',
  color: { r: 16, g: 124, b: 16, a: 1 },
  className: 'theme40'
},
{
  name: '颜色41',
  color: { r: 118, g: 118, b: 118, a: 1 },
  className: 'theme41'
},
{
  name: '颜色42',
  color: { r: 76, g: 74, b: 72, a: 1 },
  className: 'theme42'
},
{
  name: '颜色43',
  color: { r: 105, g: 121, b: 126, a: 1 },
  className: 'theme43'
},
{
  name: '颜色44',
  color: { r: 74, g: 84, b: 89, a: 1 },
  className: 'theme44'
},
{
  name: '颜色45',
  color: { r: 100, g: 124, b: 100, a: 1 },
  className: 'theme45'
},
{
  name: '颜色46',
  color: { r: 82, g: 94, b: 84, a: 1 },
  className: 'theme46'
},
{
  name: '颜色47',
  color: { r: 132, g: 117, b: 69, a: 1 },
  className: 'theme47'
},
{
  name: '颜色48',
  color: { r: 126, g: 115, b: 95, a: 1 },
  className: 'theme48'
}
]
