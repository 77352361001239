<!--
*@description 
*@author 杜超峰
*@date 2023/04/19 18:08:02
!-->

<template>
    <a-tabs class="cgs-design-tabs" :class="modeClassMap[mode]" v-bind="attrs">
        <template v-slot:[name] v-for="(_, name) in slots">
            <slot :name='name'></slot>
        </template>
    </a-tabs>
</template>

<script>
import {
    defineComponent,
    useSlots,
    useAttrs
} from 'vue'

export default defineComponent({
    name: 'cgsTabs',
    props: {
        mode: {
            type: String,
            default: 'default' //button
        }
    },
    setup() {
        const slots = useSlots();
        const attrs = useAttrs();
        let modeClassMap = {
            default: '',
            button: 'cgs-design-tabs-button-mode'
        }
        return {
            slots,
            attrs,
            modeClassMap
        }
    }
})
</script>

<style lang="less">
@import '~@style/less/theme.less';

.cgs-design-tabs.cgs-design-tabs-button-mode.ant-tabs-top>.ant-tabs-nav::before,
.cgs-design-tabs.cgs-design-tabs-button-mode.ant-tabs-bottom>.ant-tabs-nav::before,
.cgs-design-tabs.cgs-design-tabs-button-mode.ant-tabs-top>div>.ant-tabs-nav::before,
.cgs-design-tabs.cgs-design-tabs-button-mode.ant-tabs-bottom>div>.ant-tabs-nav::before {
    border-width: 0px;
}

.cgs-design-tabs.cgs-design-tabs-button-mode .ant-tabs-ink-bar {
    background: @primary-color;
}

.cgs-design-tabs.cgs-design-tabs-button-mode.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.cgs-design-tabs.cgs-design-tabs-button-mode.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.cgs-design-tabs.cgs-design-tabs-button-mode.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar,
.cgs-design-tabs.cgs-design-tabs-button-mode.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 0px;
}

.cgs-design-tabs.cgs-design-tabs-button-mode .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @font-color-1;
}

.cgs-design-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @primary-color;
}

.cgs-design-tabs.ant-tabs-top>.ant-tabs-nav::before,
.cgs-design-tabs.ant-tabs-bottom>.ant-tabs-nav::before,
.cgs-design-tabs.ant-tabs-top>div>.ant-tabs-nav::before,
.cgs-design-tabs.ant-tabs-bottom>div>.ant-tabs-nav::before {
    .border(0, 0, 1px, 0);
}

.cgs-design-tabs .ant-tabs-tab-btn:focus,
.cgs-design-tabs .ant-tabs-tab-remove:focus,
.cgs-design-tabs .ant-tabs-tab-btn:active,
.cgs-design-tabs .ant-tabs-tab-remove:active {
    color: @primary-color-08;
}

.cgs-design-tabs .ant-tabs-tab:hover {
    color: @primary-color-08;
}

.cgs-design-tabs.cgs-design-tabs-button-mode .ant-tabs-tab-btn:focus,
.cgs-design-tabs.cgs-design-tabs-button-mode .ant-tabs-tab-remove:focus,
.cgs-design-tabs.cgs-design-tabs-button-mode .ant-tabs-tab-btn:active,
.cgs-design-tabs.cgs-design-tabs-button-mode .ant-tabs-tab-remove:active {
    color: @font-color-1;
}

.cgs-design-tabs.cgs-design-tabs-button-mode .ant-tabs-tab:hover {
    color: @font-color-1;
}

.cgs-design-tabs.cgs-design-tabs-button-mode .ant-tabs-tab {
    padding: 4px 6px;
}

.cgs-design-tabs.cgs-design-tabs-button-mode .ant-tabs-tab.ant-tabs-tab-active {
    border-radius: 20px;
    background: @selected-color;
    padding: 0 10px;
}

.cgs-design-tabs .ant-tabs-ink-bar {
    background: @primary-color-08;
}

.cgs-design-tabs.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.cgs-design-tabs.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    color: @font-color-1;
}

.ant-tabs-dropdown-menu {
    background: @card-color !important;
}

.ant-tabs-dropdown-menu-item {
    color: @font-color-1 !important;
}

.ant-tabs-dropdown-menu-item:hover {
    background: @selected-color !important;
}

.cgs-design-tabs .anticon {
    color: @font-color-1
}
</style>