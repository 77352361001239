<template>
  <a-statistic :value="value" :decimal-separator="decimalSeparator" :group-separator="groupSeparator"
               :precision="precision" :v-bind="valueStyle">
    <template #formatter v-if="'formatter' in slots">
      <slot name="formatter"></slot>
    </template>
    <template #prefix v-if="'prefix' in slots">
      <slot name="prefix"></slot>
    </template>
    <template #suffix v-if="'suffix' in slots">
      <slot name="suffix"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-statistic>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsStatistic",
  props: {
    decimalSeparator: {
      type: String,
      default: '.'
    },
    groupSeparator: {
      type: String,
      default: ','
    },
    precision: {
      type: Number,
      default: undefined
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    valueStyle: {
      type: Object,
      default: undefined
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
