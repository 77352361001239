<template>
  <a-row :align="align" :gutter="gutter" :justify="justify" :wrap="wrap">
    <slot></slot>
  </a-row>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsRow",
  props: {
    align: {
      type: String,
      default: 'top'
    },
    gutter:{
      type: [Number, Object, Array],
      default: 0
    },
    justify:{
      type: String,
      default: 'start'
    },
    wrap:{
      type: Boolean,
      default: false
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
