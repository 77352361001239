<template>
  <a-anchor :affix="affix" :bounds="bounds" :getContainer="getContainer" :getCurrentAnchor="getCurrentAnchor"
  :offsetBottom="offsetBottom" :offsetTop="offsetTop" :showInkInFixed="showInkInFixed" :targetOffset="targetOffset"
  :wrapperClass="wrapperClass" :wrapperStyle="wrapperStyle">
    <slot></slot>
  </a-anchor>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsAnchor",
  props: {
    affix: {
      type: Boolean,
      default: true
    },
    bounds: {
      type: Number,
      default: 5
    },
    getContainer: {
      type: Function,
      default: () => window
    },
    getCurrentAnchor: {
      type: Function,
      default: undefined
    },
    offsetBottom: {
      type: Number,
      default: undefined
    },
    offsetTop: {
      type: Number,
      default: undefined
    },
    showInkInFixed: {
      type: Boolean,
      default: false
    },
    targetOffset: {
      type: Number,
      default: undefined
    },
    wrapperClass: {
      type: String,
      default: undefined
    },
    wrapperStyle: {
      type: Object,
      default: undefined
    }
  },
  setup(props, ctx){
    const slots = useSlots();
    const changeHandler = (currentActiveLink) => {
      ctx.emit('change', currentActiveLink)
    }
    const clickHandler = (e, link) => {
      ctx.emit('click', e, link)
    }
    return{
      slots,
      changeHandler,
      clickHandler
    }
  }
})
</script>

<style scoped>

</style>
