<template>
  <div class="cgs-design-time-ranger-picker-container">
    <a-time-range-picker class="cgs-design-time-ranger-picker" v-bind="attrs">
      <template v-for="(_, name) in slots" #[name]="scopedData">
        <slot :name='name' v-bind="scopedData"></slot>
      </template>
    </a-time-range-picker>
  </div>
</template>

<script>
  import {
    defineComponent,
    useSlots,
    useAttrs
  } from 'vue'

  export default defineComponent({
    name: "cgsTimeRangePicker",
    props: {},
    setup() {
      const slots = useSlots();
      const attrs = useAttrs()

      return {
        slots,
        attrs
      }
    }
  })
</script>

<style scoped>

</style>