<template>
  <a-divider :dashed="dashed" :orientation="orientation" :orientationMargin="orientationMargin" :plain="plain" :type="type">
    <slot></slot>
  </a-divider>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsDivider",
  props: {
    dashed: {
      type: Boolean,
      default: false
    },
    orientation:{
      type: String, //	left | right | center
      default: "center"
    },
    orientationMargin:{
      type: [String,Number],
      default: undefined
    },
    plain:{
      type: Boolean,
      default: false
    },
    type: {
      type: String, //	horizontal | vertical
      default: "horizontal"
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
