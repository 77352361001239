<template>
  <a-tree-select-node :checkable="checkable" :disableCheckbox="disableCheckbox" :disabled="disabled" :isLeaf="isLeaf"
                      :key="key" :selectable="selectable" :value="value">
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-tree-select-node>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsTreeSelectNode",
  props: {
    checkable: {
      type: Boolean,
      default: undefined,
    },
    disableCheckbox: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLeaf: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/no-reserved-props
    key: {
      type: [String, Number],
      required: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: undefined,
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
