<template>
  <a-form class="cgs-design-form" ref="cgsForm" :colon="colon" :hideRequiredMark="hideRequiredMark" :labelAlign="labelAlign" :labelCol="labelCol"
          :labelWrap="labelWrap" :layout="layout" :model="model" :name="name" :noStyle="noStyle" :rules="rules"
          :scrollToFirstError="scrollToFirstError" :validateOnRuleChange="validateOnRuleChange"
          :validateTrigger="validateTrigger" :wrapperCol="wrapperCol" @validateHandler="validateHandler"
          @submitHandler="submitHandler" @finishFailedHandler="finishFailedHandler" @finishHandler="finishHandler">
    <slot></slot>
  </a-form>
</template>

<script>
import {
  defineComponent,
  useSlots,
  ref
} from 'vue'

export default defineComponent({
  name: "cgsForm",
  props: {
    colon: {
      type: Boolean,
      default: true
    },
    hideRequiredMark: {
      type: Boolean,
      default: false
    },
    labelAlign: {
      type: String,
      default: 'right'
    },
    labelCol: {
      type: Object,
      default: undefined
    },
    labelWrap: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'horizontal'
    },
    model: {
      type: Object,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    },
    noStyle: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: undefined
    },
    scrollToFirstError: {
      type: [Boolean, Object],
      default: false
    },
    validateOnRuleChange: {
      type: Boolean,
      default: true
    },
    validateTrigger: {
      type: [String, Array],
      default: 'change'
    },
    wrapperCol: {
      type: Object,
      default: undefined
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const cgsForm = ref(null)
    const finishHandler = (values) => {
      ctx.emit('finish', values)
    }
    const finishFailedHandler = ({ values, errorFields, outOfDate }) => {
      ctx.emit('finishFailed', { values, errorFields, outOfDate })
    }
    const submitHandler = (e) => {
      ctx.emit('submit', e)
    }
    const validateHandler = (name, status, errorMsgs) => {
      ctx.emit('validate', name, status, errorMsgs)
    }
    const validateFields = () => {
      return new Promise((resolve, reject)=>{
        try{
          cgsForm.value.validateFields().then((value)=>{
            resolve(value)
          }).catch((e)=>{
            reject(e)
          })
        }catch(e){
          reject(e)
        }
      })
    }
    return{
      slots,
      cgsForm,
      finishHandler,
      finishFailedHandler,
      submitHandler,
      validateHandler,
      validateFields
    }
  }
})
</script>

<style lang="less">
@import "~@style/less/theme.less";

.cgs-design-form .ant-form-item-label > label{
  color: @font-color-1;
  .text-1();
}

.cgs-design-form .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  color: @error-color
}

</style>
