<template>
  <a-back-top :target="target" :visibilityHeight="visibilityHeight" @clickHandler="clickHandler">
    <slot></slot>
  </a-back-top>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsBackTop",
  props: {
    target: {
      type: Function,
      default: () => window
    },
    visibilityHeight: {
      type: Number,
      default: 400
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const clickHandler = () => {
      ctx.emit('clickChange')
    }
    return{
      slots,
      clickHandler
    }
  }
})
</script>

<style scoped>

</style>
