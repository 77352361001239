<template>
  <div class="cgs-design-upload">
    <a-upload v-bind="attrs">
      <template v-slot:[name] v-for="(_, name) in slots">
        <slot :name='name'></slot>
      </template>
      <!-- <template v-for="(_, name) in slots" #[name]="scopedData">
        <slot :name='name' v-bind="scopedData"></slot>
      </template> -->
      <!-- <slot></slot> -->
    </a-upload>
  </div>

</template>

<script>
  import {
    defineComponent,
    useAttrs,
    useSlots
  } from "vue";
  export default defineComponent({
    name: "cgsUpload",
    props: {},
    setup() {
      const slots = useSlots();
      const attrs = useAttrs()
      return {
        slots,
        attrs
      }
    }
  })
</script>

<style scoped>

</style>