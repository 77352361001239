<template>
  <a-tree-node class="cgs-design-tree-node" v-bind="attrs">
    <template v-for="(_, name) in slots" #[name]="scopedData">
      <slot :name='name' v-bind="scopedData"></slot>
    </template>
  </a-tree-node>
</template>

<script>
  import {
    defineComponent,
    useAttrs,
    useSlots
  } from 'vue'

  export default defineComponent({
    name: "cgsTreeNode",
    setup() {
      const slots = useSlots();
      const attrs = useAttrs()
      return {
        slots,
        attrs
      }
    }
  })
</script>

<style scoped>

</style>