<template>
  <a-radio-button v-model:checked="showChecked" :autofocus="autofocus" :disabled="disabled" :value="value" >
    <slot></slot>
  </a-radio-button>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from "vue";
export default defineComponent({
  name: "cgsRadioButton",
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: undefined
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const showChecked = computed({
      get: () => props.checked,
      set: (val) => {
        ctx.emit('update:checked', val)
      }
    })
    return{
      slots,
      showChecked
    }
  }
})
</script>

<style scoped>

</style>
