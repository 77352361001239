<template>
  <a-mentions-option :value="value">
    <slot></slot>
  </a-mentions-option>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsMentionsOption",
  props: {
    value: {
      type: String,
      default: ''
    },
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
