<template>
  <a-calendar :disabledDate="disabledDate" :fullscreen="fullscreen" :locale="locale" :mode="mode" :validRange="validRange"
  v-model:value="showValue" :valueFormat="valueFormat" @changeHandler="changeHandler" @panelChangeHandler="panelChangeHandler"
              @selectHandler="selectHandler">
    <template #dateCellRender="{current}" v-if="'dateCellRender' in slots">
      <slot name="dateCellRender" :current="current"></slot>
    </template>
    <template #dateFullCellRender="{current}" v-if="'dateFullCellRender' in slots">
      <slot name="dateFullCellRender" :current="current"></slot>
    </template>
    <template #headerRender="{value, type, onChange, onTypeChange}" v-if="'headerRender' in slots">
      <slot name="headerRender" :value="value" :type="type" :onChange="onChange" :onTypeChange="onTypeChange"></slot>
    </template>
    <template #monthCellRender="{current}" v-if="'monthCellRender' in slots">
      <slot name="monthCellRender" :current="current"></slot>
    </template>
    <template #monthFullCellRender="{current}" v-if="'monthFullCellRender' in slots">
      <slot name="monthFullCellRender" :current="current"></slot>
    </template>
    <slot></slot>
  </a-calendar>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'
import dayjs from "dayjs";

export default defineComponent({
  name: "cgsCalendar",
  props: {
    disabledDate: {
      type: Function,
      default: undefined
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    locale: {
      type: Object,
      default: undefined
    },
    mode: {
      type: String, //month/year
      default: 'month'
    },
    validRange: {
      type: Array,
      default: undefined
    },
    value: {
      type: Object,
      default: () => dayjs()
    },
    valueFormat: {
      type: [String, Object],
      default: undefined
    },
  },
  setup(props, ctx) {
    const slots = useSlots();
    const changeHandler = (date) => {
      ctx.emit('change', date)
    }
    const panelChangeHandler = (date, mode) => {
      ctx.emit('panelChange', date, mode)
    }
    const selectHandler = (date) => {
      ctx.emit('select', date)
    }
    let showValue = computed({
      get: () => props.value,
      set: (val) => {
        ctx.emit('update:value', val)
      }
    })
    return{
      slots,
      changeHandler,
      panelChangeHandler,
      selectHandler,
      showValue
    }
  }
})
</script>

<style scoped>

</style>
