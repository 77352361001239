<template>
  <a-page-header :avatar="avatar" :breadcrumb="breadcrumb" :ghost="ghost" :tags="tags" @backHandler="backHandler">
    <template #backIcon v-if="'backIcon' in slots">
      <slot name="backIcon"></slot>
    </template>
    <template #extra v-if="'extra' in slots">
      <slot name="extra"></slot>
    </template>
    <template #footer v-if="'footer' in slots">
      <slot name="footer"></slot>
    </template>
    <template #subTitle v-if="'subTitle' in slots">
      <slot name="subTitle"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-page-header>
</template>

<script>
import {
  defineComponent,
  useSlots
} from "vue";
export default defineComponent({
  name: "cgsPageHeader",
  props: {
    avatar: {
      type: Object,
      default: undefined
    },
    breadcrumb: {
      type: Object,
      default: undefined
    },
    ghost: {
      type: Boolean,
      default: true
    },
    tags: {
      type: [Array, Object],
      default: undefined
    },
  },
  setup(props, ctx){
    const slots = useSlots();
    const backHandler = (e) => {
      ctx.emit('back', e)
    }
    return{
      slots,
      backHandler
    }
  }
})
</script>

<style scoped>

</style>
