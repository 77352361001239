<template>
  <a-card :activeTabKey="activeTabKey" :bodyStyle="bodyStyle" :bordered="bordered" :defaultActiveTabKey="defaultActiveTabKey"
          :headStyle="headStyle" :hoverable="hoverable" :loading="loading" :size="size" :tabList="tabList" :type="type"
          @tabChangeHandler="tabChangeHandler">
    <template #extra v-if="'extra' in slots">
      <slot name="extra"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-card>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsCard",
  props: {
    activeTabKey: {
      type: String,
      default: undefined
    },
    bodyStyle: {
      type: Object,
      default: undefined
    },
    bordered: {
      type: Boolean,
      default: true
    },
    defaultActiveTabKey: {
      type: String,
      default: "第一个页签"
    },
    headStyle: {
      type: Object,
      default: undefined
    },
    hoverable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "default"
    },
    tabList: {
      type: Array,
      default: undefined
    },
    type: {
      type: String,
      default: undefined
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const tabChangeHandler = (key) => {
      ctx.emit('tabChange', key)
    }
    return{
      slots,
      tabChangeHandler
    }
  }
})
</script>

<style scoped>

</style>
