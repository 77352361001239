<template>
  <a-anchor-link :href="href" :target="target" :title="title">
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-anchor-link>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsAnchorLink",
  props: {
    href: {
      type: String,
      default: undefined
    },
    target: {
      type: String,
      default: undefined
    },
    title:{
      type: String,
      default : 'title',
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
