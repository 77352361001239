<template>
    <a-select-option class="cgs-design-select-option" v-bind="attrs">
        <template v-for="(_, name) in slots" #[name]="scopedData">
            <slot :name='name' v-bind="scopedData"></slot>
        </template>
    </a-select-option>
</template>

<script>
    import {
        defineComponent,
        useAttrs,
        useSlots,
    } from 'vue'

    export default defineComponent({
        name: 'cgsSelectOption',
        setup() {
            const slots = useSlots();
            const attrs = useAttrs()
            return {
                slots,
                attrs
            }
        }
    })
</script>