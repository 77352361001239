<template>
    <div class="cgs-design-select-container" ref="selectContainer">
        <a-select class="cgs-design-select" v-bind="attrs" :style="{width: '100%'}" :getPopupContainer="getContainer">
            <template v-slot:[name] v-for="(_, name) in slots">
                <slot :name='name'></slot>
            </template>
        </a-select>
    </div>

</template>

<script>
    import {
        defineComponent,
        useSlots,
        useAttrs,
        ref
    } from 'vue'

    export default defineComponent({
        name: "cgsSelect",
        props: {
            isAppendBody: {
                type: Boolean,
                default: false
            }
        },
        setup(props) {
            const slots = useSlots();
            const attrs = useAttrs();
            const selectContainer = ref(null)
            const getContainer = () => selectContainer.value
            return {
                slots,
                attrs,
                selectContainer,
                getContainer: props.isAppendBody ? null : getContainer
            }
        },
    })
</script>

<style lang="less">
    @import '~@style/less/theme.less';
    .cgs-design-select-container, .cgs-design-select{
        width: 100%;
    }

    .cgs-design-select.ant-select {
        color: @font-color-1;
    }

    .cgs-design-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        .text-1;
        background: @selected-color;
    }

    .cgs-design-select.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        // .border()
        .text-1;
        border: 1px solid @font-color-1;
    }

    .cgs-design-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        .text-1;
        border: 1px solid @font-color-1;
    }

    .cgs-design-select.ant-select-single.ant-select-open .ant-select-selection-item {
        color: @font-color-1;
    }

    .cgs-design-select.ant-select-single.ant-select-open .ant-select-arrow .anticon {
        color: @primary-color;
    }

    .cgs-design-select-dropdown.ant-select-dropdown {
        background: @card-color;
        .border();
        .card-box-shadow();
    }

    .cgs-design-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .cgs-design-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        .text-2;
        color: @primary-color;
        background: @selected-color;
    }

    .cgs-design-select-dropdown .ant-select-item {
        .text-2;
        color: @font-color-2;
    }

    .cgs-design-select-dropdown .ant-select-item-option-disabled {
        color: @font-color-disabled;
    }

    .cgs-design-select-dropdown .rc-virtual-list-holder-inner {
        padding: 10px;
    }

    .cgs-design-select .ant-select-selection-placeholder {
        color: @font-color-3;
    }

    .cgs-design-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-width: 0px;
        border: 1px solid @selected-color;
    }

    .cgs-design-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: none;
    }
</style>