<!--
*@description 
*@author 杜超峰
*@date 2023/04/19 18:08:02
!-->

<template>
  <a-modal class="cgs-design-modal" :title="title" v-model:visible="vis" :destroy-on-close="destroyOnClose"
    :wrap-className="wrapClassName" :body-style="bodyStyle" :dialog-style="dialogStyle" :width="width" @ok="handleOk"
    @cancel="handleCancel">
    <template v-slot:[name] v-for="(_, name) in slots">
        <slot :name='name'></slot>
      </template>
    <slot name="content"></slot>
  </a-modal>
</template>

<script>
import {
  defineComponent,
  useSlots,
  computed
} from 'vue'

export default defineComponent({
  name: 'cgsModal',
  props: {
    title: {
      type: [String, undefined],
      default: undefined
    },
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: 520
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    },
    wrapClassName: {
      type: String,
      default: ''
    },
    bodyStyle: {
      type: Object,
      default: () => ({})
    },
    dialogStyle: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["close", 'update:visible'],
  setup (props, ctx) {
    const slots = useSlots();
    const handleOk = () => {
      close()
    }
    const handleCancel = () => {
      close()
    }
    const close = () => {
      vis.value = false
      ctx.emit('close')
    }
    const vis = computed({
      get: () => props.visible,
      set: (val) => {
        ctx.emit('update:visible', val)
      }
    })
    return {
      slots,
      vis,
      handleOk,
      handleCancel,
      close
    }
  }
})
</script>

<style lang="less">
@import '~@style/less/theme.less';

.cgs-design-modal.ant-modal {
  color: @font-color-1;
}

.cgs-design-modal .ant-modal-content {
  background-color: @card-color;
  border-radius: 5px;
  overflow: hidden;
  .card-box-shadow();
}

.cgs-design-modal .ant-modal-header {
  background: @card-color;
  .border(0, 0, 1px, 0)
}

.cgs-design-modal .ant-modal-title {
  color: @font-color-1;
  .little-title();
  font-weight: 700;
}

.cgs-design-modal .ant-modal-footer {
  .border(1px, 0, 0, 0)
}

.cgs-design-modal .anticon {
  color: @font-color-1;
}
</style>