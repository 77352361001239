<template>
  <a-result :status="status">
    <template #extra v-if="'extra' in slots">
      <slot name="extra"></slot>
    </template>
    <template #subTitle v-if="'subTitle' in slots">
      <slot name="subTitle"></slot>
    </template>
    <template #icon v-if="'icon' in slots">
      <slot name="icon"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-result>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsResult",
  props: {
    status: {
      type: String, //success | error | info | warning | 404 | 403 | 500
      default: 'info'
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
