<template>
  <a-list :bordered="bordered" :dataSource="dataSource" :grid="grid" :itemLayout="itemLayout" :loading="loading"
          :locale="locale" :pagination="pagination" :renderItem="renderItem" :rowKey="rowKey" :size="size" :split="split"
          :position="position" :column="column" :gutter="gutter" :xxxl="xxxl" :xs="xs" :sm="sm" :md="md" :lg="lg"
          :xl="xl" :xxl="xxl">
    <template #header v-if="'header' in slots">
      <slot name="header"></slot>
    </template>
    <template #loadMore v-if="'loadMore' in slots">
      <slot name="loadMore"></slot>
    </template>
    <template #footer v-if="'footer' in slots">
      <slot name="footer"></slot>
    </template>
    <template #renderItem="{item, index}" v-if="'renderItem' in slots">
      <slot name="renderItem" :item="item" :index="index"></slot>
    </template>
    <slot></slot>
  </a-list>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsList",
  props: {
    bordered: {
      type: Boolean,
      default: false
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    grid: {
      type: Object,
      default: undefined
    },
    itemLayout: {
      type: String,
      default: undefined
    },
    loading: {
      type: [Boolean, Object],
      default: false
    },
    locale: {
      type: Object,
      default: () => ({ emptyText: '暂无数据' })
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    renderItem: {
      type: Function,
      default: undefined
    },
    rowKey: {
      type: [Function, String],
      default: undefined
    },
    size: {
      type: String,
      default: 'default'
    },
    split: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,//	'top' | 'bottom' | 'both'
      default: 'bottom'
    },
    column: {
      type: Number,
      default: undefined
    },
    gutter: {
      type: Number,
      default: 0
    },
    xxxl: {
      type: Number,
      default: undefined
    },
    xs: {
      type: Number,
      default: undefined
    },
    sm: {
      type: Number,
      default: undefined
    },
    md: {
      type: Number,
      default: undefined
    },
    lg: {
      type: Number,
      default: undefined
    },
    xl: {
      type: Number,
      default: undefined
    },
    xxl: {
      type: Number,
      default: undefined
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
