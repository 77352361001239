import defaultThemeScheme from './themeVars'
import StyleVarOperator from './styleVarOperator'

export default class ThemeManager {
    constructor(themeScheme = defaultThemeScheme, defaultThemeName = null) {
        this._themeScheme = themeScheme
        this._currentThemeName = this._initCurrentThemeName(defaultThemeName)
        this._styleVarOperator = new StyleVarOperator();
    }

    addTheme(themeName = null) {
        if(themeName !== null && themeName in this._themeScheme){
            this._currentThemeName = themeName
        }
        if(!(this._currentThemeName in this._themeScheme)) return 
        let _currentTheme = this._themeScheme[this._currentThemeName]
        Object.keys(_currentTheme).forEach(varName => {
            let _varValue = _currentTheme[varName]
            this._styleVarOperator.addThemeVar(varName, _varValue)
        })
    }

    replaceTheme(themeName = null) {
        if(themeName !== null && themeName in this._themeScheme){
            this._currentThemeName = themeName
        }
        if(!(this._currentThemeName in this._themeScheme)) return 
        let _currentTheme = this._themeScheme[this._currentThemeName]
        Object.keys(_currentTheme).forEach(varName => {
            let _varValue = _currentTheme[varName]
            this._styleVarOperator.replaceThemeVar(varName, _varValue)
        })
    }

    resetThemeScheme(themeScheme, defaultThemeName = null) {
        this._themeScheme = themeScheme
        this._currentThemeName = this._initCurrentThemeName(defaultThemeName)
    }

    _initCurrentThemeName(themeName) {
        let _name = null
        if (themeName) {
            _name = themeName
        } else {
            let _names = Object.keys(this._themeScheme)
            if (_names.length > 0) _name = _names[0]
        }
        return _name
    }

    /**
     * 将样式变量写入，如果已存在，则不写入
     * @param {String} name 样式变量名
     * @param {String} value 样式变量值
     */
     addThemeVar(name, value) {
        if (!this._hasVarName(name)) {
            this._styleVarOperator.addThemeVar(name, value)
        }
    }

    /**
     * 替换原有样式变量的值，如果该颜色变量不存在，则新增
     * @param {String} name 
     * @param {String} value 
     */
    replaceThemeVar(name, value){
        this._styleVarOperator.replaceThemeVar(name, value)
    }

    /**
     *删除样式变量
     * @param {String} name 
     */
    removeThemeVar(name){
        this._styleVarOperator.removeThemeVar(name)
    }




}