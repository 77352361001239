<!--
*@description
*@author 杜超峰
*@date 2023/04/19 17:53:46
!-->

<template>
    <a-input class="cgs-design-input"
        :class="[modeClassMap[mode], backgroundTransparent ? 'cgs-design-input-background-transparent' : '']"
        v-bind="attrs">
        <template v-slot:[name] v-for="(_, name) in slots">
            <slot :name='name'></slot>
        </template>
    </a-input>
</template>

<script>
    import {
        defineComponent,
        useSlots,
        useAttrs
    } from 'vue'

    export default defineComponent({
        name: "cgsInput",
        props: {
            backgroundTransparent: {
                type: Boolean,
                default: false
            },
            mode: {
                type: String,
                default: 'default' //default  noBorder
            },
        },

        setup() {
            let modeClassMap = {
                default: '',
                noBorder: 'cgs-design-input-portal-noBorder'
            }
            const slots = useSlots();
            const attrs = useAttrs();
            return {
                slots,
                attrs,
                modeClassMap
            }
        },
    })
</script>

<style lang="less">
    @import '~@style/less/theme.less';

    .cgs-design-input.ant-input-affix-wrapper>input.ant-input {
        .text-2();
        color: @font-color-1;
    }

    .cgs-design-input.ant-input {
        .text-2();
        color: @font-color-1;
        background: @card-color;
        border: 1px solid @selected-color;
    }

    .cgs-design-input.ant-input:focus,
    .ant-input-focused {
        border: 1px solid @font-color-1;
        box-shadow: none;
    }

    .cgs-design-input.ant-input:hover {
        border-color: @font-color-1;
    }
</style>

<style lang="less">
    @import '~@style/less/theme.less';

    .cgs-design-input.ant-input-affix-wrapper {
        .text-2;
        color: @font-color-1;
        background: @card-color;
        // border: 1px solid @selected-color;
        .border();
    }

    .cgs-design-input.cgs-design-input-portal-noBorder.ant-input-affix-wrapper {
        border-color: transparent;
    }

    .cgs-design-input.cgs-design-input-background-transparent.ant-input-affix-wrapper {
        background: transparent;
    }

    .cgs-design-input.ant-input-affix-wrapper>input.ant-input {
        background: transparent;
    }

    .cgs-design-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: @font-color-1;
    }

    .cgs-design-input.ant-input-affix-wrapper:focus,
    .cgs-design-input.ant-input-affix-wrapper-focused {
        .card-box-shadow();
    }
</style>