<template>
    <a-textarea class="cgs-design-textArea"
        v-model:value="showValue" :allowClear="allowClear"  :defaultValue="defaultValue"
        :showCount="showCount" @pressEnter="pressEnterHandler">
    </a-textarea>
</template>

<script>
    import {
        defineComponent,
        computed,
    } from 'vue'

    export default defineComponent({
        name: "cgsTextarea",
        props: {
            value: {
                type: String,
                default: ''
            },
            allowClear: {
                type: Boolean,
                default: undefined
            },
            autosize: {
                type: [Boolean,Object],
                default: false
            },
            defaultValue: {
                type: String,
                default: ''
            },
            showCount: {
                type: Boolean,
                default: false
            }
        },

        setup(props, ctx) {
            const pressEnterHandler = (e) => {
                ctx.emit('pressEnter', e)
            }
            const showValue = computed({
                get: () => props.value,
                set: (val) => {
                    ctx.emit('update:value', val)
                }
            })
            return {
                showValue,
                pressEnterHandler
            }
        },
    })
</script>

<style lang="less">
    @import '~@style/less/theme.less';
    .cgs-design-textArea.ant-input-affix-wrapper{
        background-color:@selected-color;
    }
    .cgs-design-textArea.ant-input{
        background-color:@selected-color;
        .text-2;
        border: 1px solid @selected-color;
    }
    .cgs-design-textArea.ant-input:hover{
        border-color:@font-color-1;
        .card-box-shadow()
    }
    .cgs-design-textArea.ant-input:focus, .ant-input-focused{
        border-color:@font-color-1;
        .card-box-shadow()
    }
    .cgs-design-textArea.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
        .card-box-shadow()
    }
</style>