<template>
  <a-space :align="align" :direction="direction" :size="size">
    <slot></slot>
  </a-space>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsSpace",
  props: {
    align: {
      type: String,
      default: undefined
    },
    direction:{
      type: String, //	vertical | horizontal
      default: 'horizontal'
    },
    size:{
      type: Number,//	small | middle | large | number
      default: 12
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
