<template>
  <div class="cgs-design-tree-select-container" ref="container">
    <a-tree-select
      class="cgs-design-tree-select"
      :style="{ width: '100%' }"
      v-bind="attrs"
      :getPopupContainer="getContainer"
    >
      <template v-for="(_, name) in slots" #[name]="scopedData">
        <slot :name="name" v-bind="scopedData"></slot>
      </template>
    </a-tree-select>
  </div>
</template>

<script>
import { defineComponent, useSlots, useAttrs, ref } from "vue";

export default defineComponent({
  name: "cgsTreeSelect",
  props: {
    isAppendBody: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const slots = useSlots();
    const attrs = useAttrs();
    const container = ref(null);
    const getContainer = () => container.value;
    return {
      slots,
      attrs,
      container,
      getContainer: props.isAppendBody ? null : getContainer,
    };
  },
});
</script>

<style scoped>
.cgs-design-tree-select-containe,
.cgs-design-tree-select {
  width: 100%;
}
</style>
