<template>
  <a-pagination class="cgs-design-pagination" v-model:current="showCurrent" v-model:pageSize="showPageSize"
    :defaultPageSize="defaultPageSize" :disabled="disabled" :hideOnSinglePage="hideOnSinglePage"
    :pageSizeOptions="pageSizeOptions" :responsive="responsive" :showLessItems="showLessItems"
    :showQuickJumper="showQuickJumper" :showSizeChanger="showSizeChanger" :showTotal="showTotal" :simple="simple"
    :size="size" :total="total" @showSizeChange="showSizeChangeHandler">
    <template #itemRender v-if="'itemRender' in slots">
      <slot name="itemRender"></slot>
    </template>
    <slot></slot>
  </a-pagination>
</template>

<script>
  import {
    computed,
    defineComponent,
    useSlots
  } from "vue";

  export default defineComponent({
    name: "cgsPagination",
    props: {
      current: {
        type: Number,
        default: undefined
      },
      pageSize: {
        type: Number,
        default: undefined
      },
      defaultPageSize: {
        type: Number,
        default: 10
      },
      disabled: {
        type: Boolean,
        default: undefined
      },
      hideOnSinglePage: {
        type: Boolean,
        default: false
      },
      pageSizeOptions: {
        type: Array,
        default: () => ['10', '20', '30', '40']
      },
      responsive: {
        type: Boolean,
        default: undefined
      },
      showLessItems: {
        type: Boolean,
        default: false
      },
      showQuickJumper: {
        type: Boolean,
        default: false
      },
      showSizeChanger: {
        type: Boolean,
        default: undefined
      },
      showTotal: {
        type: Function,
        default: undefined
      },
      simple: {
        type: Boolean,
        default: undefined
      },
      size: {
        type: Number,
        default: undefined
      },
      total: {
        type: Number,
        default: 0
      }
    },
    setup(props, ctx) {
      const slots = useSlots();
      // const changeHandler = (page, pageSize) => {
      //   ctx.emit('change', page, pageSize)
      // }
      const showSizeChangeHandler = (current, size) => {
        ctx.emit('showSizeChange', current, size)
      }
      const showCurrent = computed({
        get: () => props.current,
        set: (val) => {
          ctx.emit('update:current', val)
        }
      })
      const showPageSize = computed({
        get: () => props.pageSize,
        set: (val) => {
          ctx.emit('update:pageSize', val)
        }
      })
      return {
        slots,
        // changeHandler,
        showSizeChangeHandler,
        showCurrent,
        showPageSize
      }
    }
  })
</script>


<style lang='less'>
  @import '~@style/less/theme.less';

  .cgs-design-pagination .ant-pagination-prev .ant-pagination-item-link,
  .cgs-design-pagination .ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
    .border()
  }

  .cgs-design-pagination.ant-pagination {
    color: @font-color-1;
  }

  .cgs-design-pagination .ant-pagination-prev button,
  .cgs-design-pagination .ant-pagination-next button {
    color: @font-color-1;
  }

  .cgs-design-pagination .ant-pagination-item {
    color: @font-color-1;
    background-color: transparent;
    .border()
  }

  .cgs-design-pagination .ant-pagination-item a {
    color: @font-color-2;
  }

  .cgs-design-pagination .ant-pagination-item-active a {
    color: @font-color-1;
  }

  .cgs-design-pagination .ant-pagination-item-active {
    background: @selected-color;
  }

  .cgs-design-pagination .ant-pagination-item:hover {
    border-color: transparent;
    background: @selected-color;
  }

  .cgs-design-pagination .ant-pagination-item:hover a {
    color: @font-color-1;
  }

  .cgs-design-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
  .cgs-design-pagination .ant-pagination-next:hover .ant-pagination-item-link {
    color: @font-color-2;
    border-color: transparent;
    background: @selected-color;
  }

  .cgs-design-pagination .ant-select {
    color: @font-color-2;
  }

  .cgs-design-pagination .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    .border();
  }

  .cgs-design-pagination .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: @border-color;
  }

  .cgs-design-pagination .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: @border-color;
  }

  .cgs-design-pagination .ant-select-dropdown {
    background-color: @card-color;
    .text-2();
    .border();
    color: @font-color-2;
  }

  .cgs-design-pagination .ant-select-item {
    color: @font-color-2;
  }

  .cgs-design-pagination .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {

    color: @font-color-2;
  }

  .cgs-design-pagination .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: @selected-color;
  }

  .cgs-design-pagination .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: @selected-color;
  }

  .cgs-design-pagination .ant-pagination-disabled .ant-pagination-item-link,
  .cgs-design-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: @font-color-3;
  }

  .cgs-design-pagination .ant-pagination-disabled .ant-pagination-item-link,
  .cgs-design-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
    background-color: transparent;
    border-color: @border-color;
  }

  .cgs-design-pagination.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    color: @font-color-3;
    background-color: transparent;
    border-color: @border-color;
  }

  .cgs-design-pagination.ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: transparent;
    border-color: @border-color;
  }

  .cgs-design-pagination.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: @font-color-3;
  }

  .cgs-design-pagination.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    color: @font-color-3;
  }

  .cgs-design-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .cgs-design-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: @font-color-2;
  }

  .cgs-design-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .cgs-design-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: @font-color-1;
  }

  .cgs-design-pagination .ant-pagination-options-quick-jumper input {
    color: @font-color-2;
    background: transparent;
    .border()
  }

  .cgs-design-pagination .ant-pagination-options-quick-jumper input:focus,
  .cgs-design-pagination .ant-pagination-options-quick-jumper input-focused {
    .border();
    .card-box-shadow()
  }
  .cgs-design-pagination .ant-pagination-options-quick-jumper input:hover{
    border-color: @border-color;
  }
  .cgs-design-pagination .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color: @font-color-3;
    background: transparent;
  }
</style>