<template>
  <a-affix :offsetBottom="offsetBottom" :offsetTop="offsetTop" :target="target" @changeHandler="changeHandler">
    <slot></slot>
  </a-affix>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsAffix",
  props: {
    offsetBottom: {
      type: Number,
      default: undefined
    },
    offsetTop:{
      type: Number,
      default: 0
    },
    target:{
      type: Function,
      default: 	() => window
    }
  },
  setup(props, ctx){
    const slots = useSlots();
    const changeHandler = (affixed) => {
      ctx.emit('change', affixed)
    }
    return{
      slots,
      changeHandler
    }
  }
})
</script>

<style scoped>

</style>
