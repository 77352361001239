<template>
  <a-descriptions-item :contentStyle="contentStyle" :labelStyle="labelStyle" :span="span">
    <template #label v-if="'label' in slots">
      <slot name="label"></slot>
    </template>
    <slot></slot>
  </a-descriptions-item>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsDescriptionsItem",
  props: {
    contentStyle: {
      type: Object,
      default: null
    },
    labelStyle: {
      type: Object,
      default: null
    },
    span: {
      type: Number,
      default: 1
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
