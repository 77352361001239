<template>
  <a-layout :class="'class'" :hasSider="hasSider" :style="style">
    <slot></slot>
  </a-layout>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsLayout",
  props: {
    class: {
      type: String,
      default: undefined
    },
    hasSider:{
      type: Boolean,
      default: undefined
    },
    style:{
      type: Object,
      default: undefined
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
