<template>
  <a-switch class="cgs-design-switch" v-model:checked="showChecked" :autofocus="autofocus" :checkedValue="checkedValue" :disabled="disabled"
            :loading="loading" :size="size" :unCheckedValue="unCheckedValue">
    <template #checkedChildren v-if="'checkedChildren' in slots">
      <slot name="checkedChildren"></slot>
    </template>
    <template #unCheckedChildren v-if="'unCheckedChildren' in slots">
      <slot name="unCheckedChildren"></slot>
    </template>
    <slot></slot>
  </a-switch>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsSwitch",
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean, //	checkedValue | unCheckedValue
      default: undefined
    },
    checkedValue: {
      type: [Boolean, String, Number],
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    unCheckedValue: {
      type: [Boolean, String, Number],
      default: false
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const changeHandler = (checked, event) => {
      ctx.emit('change', checked, event)
    }
    const clickHandler = (checked, event) => {
      ctx.emit('change', checked, event)
    }
    const showChecked = computed({
      get: () => props.checked,
      set: (val) => {
        ctx.emit('update:checked', val)
      }
    })
    return{
      slots,
      changeHandler,
      clickHandler,
      showChecked
    }
  }
})
</script>

<style lang="less">
@import "~@style/less/theme.less";

.cgs-design-switch.ant-switch{
  color: @font-color-1;
  background-color: @selected-color;
}

.cgs-design-switch.ant-switch.ant-switch-checked{
  background: @primary-color;
}

.cgs-design-switch.ant-switch .ant-switch-inner{
  color: @font-color-3;
}

.cgs-design-switch.ant-switch.ant-switch-checked .ant-switch-inner{
  color: @foreground-color-dark-background;
}

</style>
