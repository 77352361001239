<template>
  <a-empty :imageStyle="imageStyle">
    <template #description v-if="'description' in slots">
      <slot name="description"></slot>
    </template>
    <template #image v-if="'image' in slots">
      <slot name="image"></slot>
    </template>
  </a-empty>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsEmpty",
  props: {
    imageStyle: {
      type: Object,
      default: undefined
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
