<template>
  <a-slider v-model:value="showValue" :autofocus="autofocus" :disabled="disabled" :dots="dots"
            :getTooltipPopupContainer="getTooltipPopupContainer" :included="included" :marks="marks" :max="max" :min="min"
            :range="range" :reverse="reverse" :step="step" :tipFormatter="tipFormatter" :tooltipPlacement="tooltipPlacement"
            :tooltipVisible="tooltipVisible" :vertical="vertical">
    <template #mark v-if="'mark' in slots">
      <slot name="mark"></slot>
    </template>
    <slot></slot>
  </a-slider>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsSlider",
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dots: {
      type: Boolean,
      default: false
    },
    getTooltipPopupContainer: {
      type: Function,
      default: () => document.body
    },
    included: {
      type: Boolean,
      default: true
    },
    marks: {
      type: Object,
      default: undefined
    },
    max: {
      type: Number,
      default: 100
    },
    min: {
      type: Number,
      default: 0
    },
    range: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    tipFormatter: {
      type: Function,
      default: undefined
    },
    tooltipPlacement: {
      type: String,
      default: undefined
    },
    tooltipVisible: {
      type: Boolean,
      default: undefined
    },
    value: {
      type: [Number, Array],
      default: undefined
    },
    vertical: {
      type: Boolean,
      default: false
    },
  },
  setup(props, ctx){
    const slots = useSlots();
    const blurHandler = () => {
      ctx.emit('blur')
    }
    const focusHandler = () => {
      ctx.emit('focus')
    }
    const changeHandler = (value) => {
      ctx.emit('change', value)
    }
    const afterChangeHandler = (value) => {
      ctx.emit('afterChange', value)
    }
    const showValue = computed({
      get: () => props.value,
      set: (val) => {
        ctx.emit('update:value', val)
      }
    })
    return{
      slots,
      blurHandler,
      focusHandler,
      changeHandler,
      afterChangeHandler,
      showValue
    }
  }
})
</script>

<style scoped>

</style>
