<template>
  <a-avatar :alt="alt" :cross-origin="crossOrigin" :draggable="draggable" :gap="gap"
            :load-error="loadError" :shape="shape" :size="size" :src="src" :srcset="srcset">
    <template #icon v-if="'icon' in slots">
      <slot name="icon"></slot>
    </template>
    <slot></slot>
  </a-avatar>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsAvatar",
  props: {
    alt: {
      type: String,
      default: undefined
    },
    crossOrigin: {
      type: String,
      default: undefined
    },
    draggable: {
      type: [Boolean, String],
      default: undefined
    },
    gap: {
      type: Number,
      default: 4
    },
    loadError: {
      type: Function,
      default: undefined
    },
    shape: {
      type: String,
      default: 'circle'
    },
    size: {
      type: [Number, String, Object],
      default: 'default'
    },
    src: {
      type: String,
      default: undefined
    },
    srcset: {
      type: String,
      default: undefined
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
