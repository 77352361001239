<template>
  <a-breadcrumb-item :href="href" :overlay="overlay">
    <slot></slot>
  </a-breadcrumb-item>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsBreadcrumbItem",
  props: {
    href: {
      type: String,
      default: undefined
    },
    overlay:{
      type: [Object, Function],
      default: undefined
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
