<template>
  <a-collapse-panel :collapsible="collapsible" :forceRender="forceRender" :key="key" :showArrow="showArrow">
    <template #extra v-if="'extra' in slots">
      <slot name="extra"></slot>
    </template>
    <template #header v-if="'header' in slots">
      <slot name="header"></slot>
    </template>
    <slot></slot>
  </a-collapse-panel>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsCollapsePanel",
  props: {
    collapsible: {
      type: [String, Boolean],
      default: undefined,
      // header: allow collapse by clicking the header
      // disabled: disable collapse for all panels
    },
    forceRender: {
      type: Boolean,
      default: false
    },
    // eslint-disable-next-line vue/no-reserved-props
    key: {
      type: [String, Number],
      default: undefined
    },
    showArrow: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
