<template>
  <a-mentions :autofocus="autofocus" :defaultValue="defaultValue" :filterOption="filterOption" :getPopupContainer="getPopupContainer"
            :placement="placement" :prefix="prefix" :split="split" :validateSearch="validateSearch" v-model:value="showValue"
            @blurHandler="blurHandler" @changeHandler="changeHandler" @focusHandler="focusHandler" @searchHandler="searchHandler"
            @selectHandler="selectHandler">
    <slot></slot>
  </a-mentions>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsMentions",
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    defaultValue:{
      type: String,
      default: undefined
    },
    filterOption:{
      type: Function,
      default: undefined
    },
    getPopupContainer:{
      type: Function,
      default: undefined
    },
    placement:{
      type: String,//top | bottom
      default: 'bottom'
    },
    prefix:{
      type: [String, Array],
      default: '@'
    },
    split:{
      type: String,
      default: ' '
    },
    validateSearch:{
      type: Function,
      default: undefined
    },
    value:{
      type: String,
      default: undefined
    },
  },
  setup(props, ctx){
    const slots = useSlots();
    const blurHandler = () => {
      ctx.emit('blur')
    }
    const changeHandler = (value) => {
      ctx.emit('change', value)
    }
    const focusHandler = () => {
      ctx.emit('focus')
    }
    const searchHandler = (value, prefix) => {
      ctx.emit('search', value, prefix)
    }
    const selectHandler = (option, prefix) => {
      ctx.emit('select', option, prefix)
    }
    const showValue = computed({
      get: () => props.value,
      set: (val) => {
        ctx.emit('update:value', val)
      }
    })
    return{
      slots,
      blurHandler,
      changeHandler,
      focusHandler,
      searchHandler,
      selectHandler,
      showValue
    }
  }
})
</script>

<style scoped>

</style>
