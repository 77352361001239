<template>
  <div class="myloading" v-show="visible">
    <div class="loading">
      <loading-outlined />
      加载中
    </div>

  </div>
</template>

<script>
import { LoadingOutlined } from '@ant-design/icons-vue';
export default {
  name: "loading",
  components: {
    LoadingOutlined
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style scoped>
.myloading {
  display: revert;
  position: fixed;
  z-index: 9999;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5)
}

.myloading .loading {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  padding: 8px 10px;
  top: 50%;
  left: 50%;
}
</style>