<template>
  <a-message :content="content" :duration="duration" :onClose="onClose" :class="'class'" :icon="icon"
             :key="key" :style="style" :onClick="onClick" :getContainer="getContainer" :maxCount="maxCount"
             :prefixCls="prefixCls" :rtl="rtl" :top="top" >
    <slot></slot>
  </a-message>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsMessage",
  props: {
    content: {
      type: [String, Function],
      default: undefined
    },
    duration: {
      type: Number,
      default: 3
    },
    onClose: {
      type: Function,
      default: undefined
    },
    class: {
      type: String,
      default: undefined
    },
    icon: {
      type: [Object, Function],
      default: undefined
    },
    // eslint-disable-next-line vue/no-reserved-props
    key: {
      type: [String, Number],
      default: undefined
    },
    style: {
      type: Object,
      default: undefined
    },
    onClick: {
      type: Function,
      default: undefined
    },
    getContainer: {
      type: Function,
      default: () => document.body
    },
    maxCount: {
      type: Number,
      default: undefined
    },
    prefixCls: {
      type: String,
      default: 'ant-message'
    },
    rtl: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: '8px'
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
