<template>
  <a-badge :color="color" :dot="dot" :number-style="numberStyle" :offset="offset"
           :overflow-count="overflowCount" :show-zero="showZero" :status="status" :text="text" :title="title">
    <template #count v-if="'count' in slots">
      <slot name="count"></slot>
    </template>
    <slot></slot>
  </a-badge>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsBadge",
  props: {
    color: {
      type: String,
      default: undefined
    },
    dot: {
      type: Boolean,
      default: false
    },
    numberStyle: {
      type: Object,
      default: () => ({})
    },
    offset: {
      type: [Number, String, Array],
      default: () => [0, 0]
    },
    overflowCount: {
      type: Number,
      default: 99
    },
    showZero: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'count'
    },
  },
  setup(){
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
