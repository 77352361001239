<!--
*@description 与modal的区别在于，modal是不支持加载到body下的，只能加载到当前的dom中
*@author 杜超峰
*@date 2023/04/19 18:08:02
!-->

<template>
  <div class="cgs-design-modal-inner-container" ref="modalInnerContainer">
    <a-modal class="cgs-design-modal-inner" :title="title" v-model:visible="vis" :getContainer="getContainer" :destroy-on-close="destroyOnClose"
      :wrap-className="wrapClassName" :body-style="bodyStyle" :dialog-style="dialogStyle" :width="width" @ok="handleOk"
      @cancel="handleCancel">
      <!-- <template v-for="(_, name) in slots" #[name]="scopedData">
        <slot :name='name' v-bind="scopedData"></slot>
      </template> -->
      <template v-slot:[name] v-for="(_, name) in slots">
        <slot :name='name'></slot>
      </template>
      <slot name="content"></slot>
    </a-modal>
  </div>

</template>

<script>
  import {
    defineComponent,
    useSlots,
    computed,
    ref
  } from 'vue'

  export default defineComponent({
    name: 'cgsModalInner',
    props: {
      title: {
        type: [String, undefined],
        default: undefined
      },
      visible: {
        type: Boolean,
        default: false
      },
      width: {
        type: [Number, String],
        default: 520
      },
      destroyOnClose: {
        type: Boolean,
        default: false
      },
      wrapClassName: {
        type: String,
        default: ''
      },
      bodyStyle: {
        type: Object,
        default: () => ({})
      },
      dialogStyle: {
        type: Object,
        default: () => ({})
      },
      isAppendBody: {
        type: Boolean,
        default: false
      }
    },
    emits: ["close", 'update:visible'],
    setup(props, ctx) {
      const slots = useSlots();
      const modalInnerContainer = ref(null)
      const handleOk = () => {
        close()
      }
      const handleCancel = () => {
        close()
      }
      const close = () => {
        vis.value = false
        ctx.emit('close')
      }
      const vis = computed({
        get: () => props.visible,
        set: (val) => {
          ctx.emit('update:visible', val)
        }
      })
      const getContainer = () => modalInnerContainer.value
      return {
        slots,
        vis,
        modalInnerContainer,
        handleOk,
        handleCancel,
        close,
        getContainer: props.isAppendBody ? null : getContainer
      }
    }
  })
</script>

<style lang="less">
  @import '~@style/less/theme.less';

  .cgs-design-modal-inner.ant-modal {
    color: @font-color-1;
  }

  .cgs-design-modal-inner .ant-modal-content {
    background-color: @card-color;
    border-radius: 5px;
    overflow: hidden;
    .card-box-shadow();
  }

  .cgs-design-modal-inner .ant-modal-header {
    background: @card-color;
    .border(0, 0, 1px, 0)
  }

  .cgs-design-modal-inner .ant-modal-title {
    color: @font-color-1;
    .little-title();
    font-weight: 700;
  }

  .cgs-design-modal-inner .ant-modal-footer {
    .border(1px, 0, 0, 0)
  }

  .cgs-design-modal-inner .anticon {
    color: @font-color-1;
  }
</style>