<!--
*@description 
*@author 杜超峰
*@date 2023/04/25 18:32:02
!-->

<template>
    <a-menu class="cgs-design-menu" :class="modeClassMap[typeMode]" v-bind="attrs" >
        <slot></slot>
        <template v-slot:[name] v-for="(_, name) in slots">
        <slot :name='name'></slot>
      </template>
    </a-menu>
</template>

<script>
    import {
        defineComponent,
        useSlots,
        useAttrs
    } from 'vue'

    export default defineComponent({
        name: "cgsMenu",
        props: {
            typeMode: {
                type: String,
                default: 'default' //default  portal
            }
        },

        setup() {
            const slots = useSlots()
            const attrs = useAttrs()
            let modeClassMap = {
                default: '',
                portal: 'cgs-design-menu-portal-mode'
            }
            return {
                slots,
                attrs,
                modeClassMap

            }
        },
    })
</script>

<style lang='less'>
 @import '~@style/less/theme.less';
 .cgs-design-menu-portal-mode.ant-menu{
    .text-2;
    color: @font-color-2;
    text-align: center;
    background: @card-color;
 }
.cgs-design-menu-portal-mode.ant-menu-vertical{
    border-right:0px solid @card-color;
}
.cgs-design-menu-portal-mode.ant-menu-light .ant-menu-item:hover{
    color: @primary-color;
    background: @selected-color;
}
.cgs-design-menu-portal-mode .ant-menu-item:active, .ant-menu-submenu-title:active{
    background: @primary-color;
}
.cgs-design-menu-portal-mode.ant-menu-light .ant-menu-submenu-title:hover{
    color: @primary-color;
    background: @selected-color;
}
.cgs-design-menu-portal-mode .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
    color: @primary-color;
}
.cgs-design-menu-portal-mode.ant-menu-light .ant-menu-submenu-active{
    color: @primary-color;
    background: @selected-color;
}
.cgs-design-menu-portal-mode.ant-menu-light .ant-menu-submenu-active .ant-menu-submenu-arrow{
    color: @primary-color;
}
</style>