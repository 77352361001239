<template>
  <a-col :flex="flex" :offset="offset" :order="order" :pull="pull" :push="push" :span="span" :xxxl="xxxl" :xs="xs"
          :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
    <slot></slot>
  </a-col>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsCol",
  props: {
    flex: {
      type: [String, Number],
      default: undefined
    },
    offset:{
      type: Number,
      default: 0
    },
    order:{
      type: Number,
      default: 0
    },
    pull:{
      type: Number,
      default: 0
    },
    push:{
      type: Number,
      default: 0
    },
    span:{
      type: Number,
      default: undefined
    },
    xxxl:{
      type: [Number, Object],
      default: undefined
    },
    xs:{
      type: [Number, Object],
      default: undefined
    },
    sm:{
      type: [Number, Object],
      default: undefined
    },
    md:{
      type: [Number, Object],
      default: undefined
    },
    lg:{
      type: [Number, Object],
      default: undefined
    },
    xl:{
      type: [Number, Object],
      default: undefined
    },
    xxl:{
      type: [Number, Object],
      default: undefined
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
