<!--
*@description 
*@author 杜超峰
*@date 2023/04/19 18:08:02
!-->

<template>
    <a-tab-pane class="cgs-design-tabpane" v-bind="attrs">
        <template v-slot:[name] v-for="(_, name) in slots">
            <slot :name='name'></slot>
        </template>
    </a-tab-pane>
</template>

<script>
    import {
        defineComponent,
        useSlots,
        useAttrs
    } from 'vue'

    export default defineComponent({
        name: 'cgsTabPane',
        props: {},
        setup() {
            const slots = useSlots();
            const attrs = useAttrs();
            return {
                slots,
                attrs
            }
        }
    })
</script>

<style lang="less">
    @import '~@style/less/theme.less';
</style>