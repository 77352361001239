<!--
*@description 
*@author 杜超峰
*@date 2023/04/23 15:12:43
!-->

<template>
    <a-config-provider :autoInsertSpaceInButton="autoInsertSpaceInButton" :componentSize="componentSize" :csp="csp"
        :direction="direction" :dropdownMatchSelectWidth="dropdownMatchSelectWidth" :form="form"
        :getPopupContainer="getPopupContainer" :getTargetContainer="getTargetContainer" :input="input" :locale="state.locale"
        :pageHeader="pageHeader" :prefixCls="prefixCls" :space="space" :transformCellText="transformCellText"
        :virtual="virtual">
        <slot></slot>
    </a-config-provider>
</template>

<script>
    import {
        watch,
        defineComponent,
        onMounted,
        reactive
    } from 'vue'
    import zhCN from 'ant-design-vue/es/locale/zh_CN';
    import enUS from 'ant-design-vue/es/locale/en_US';
    import dayjs from 'dayjs';
    import 'dayjs/locale/zh-cn';

    dayjs.locale('zh-cn');
    export default defineComponent({
        name: 'cgsConfigProvider',
        components: {},
        props: {
            themeName: {
                type: [String, undefined],
                default: undefined
            },
            themeScheme: {
                type: [Object, undefined],
                default: undefined
            },
            autoInsertSpaceInButton: {
                type: Boolean,
                default: true
            },
            componentSize: {
                type: [String, undefined],
                default: undefined
            },
            csp: {
                type: [Object, undefined],
                default: undefined
            },
            direction: {
                type: [String],
                default: 'ltr'
            },
            dropdownMatchSelectWidth: {
                type: [Boolean, Number, undefined],
                default: undefined
            },
            form: {
                type: [Object, undefined],
                default: undefined
            },
            getPopupContainer: {
                type: Function,
                default: () => document.body
            },
            getTargetContainer: {
                type: Function,
                default: () => window
            },
            input: {
                type: [Object, undefined],
                default: undefined
            },
            locale: {
                type: String,
                default: 'zh-CN'
            },
            pageHeader: {
                type: [Object],
                default: () => {
                    return {
                        ghot: true
                    }
                }
            },
            prefixCls: {
                type: String,
                default: 'ant'
            },
            space: {
                type: Object,
                default: () => {
                    return {
                        size: 'middle'
                    }
                }
            },
            transformCellText: {
                type: [Function, undefined],
                default: undefined
            },
            virtual: {
                type: [Boolean, undefined],
                default: undefined
            }
        },

        setup(props) {
            let langMap = {
                'zh-CN': {
                    locale: zhCN,
                    dayjs: 'zh-cn'
                },
                'en-US': {
                    locale: enUS,
                    dayjs: 'en-us'
                }
            }
            const state = reactive({
                locale: zhCN
            })
            const setThemeScheme = (themeScheme) => {
                window.cgsDesignThemeManager.resetThemeScheme(themeScheme)
            }
            const setThemeName = (themeName) => {
                window.cgsDesignThemeManager.replaceTheme(themeName)
            }
            watch(() => props.locale, (newVal) => {
                if (newVal in langMap){
                    state.locale = langMap[newVal].locale
                    dayjs.locale(langMap[newVal].dayjs);
                }
            }, {
                immediate: true
            })
            onMounted(() => {
                props.themeScheme && setThemeScheme(props.themeScheme)
                props.themeName && setThemeScheme(props.themeName)
            })
            return {
                state,
                setThemeScheme,
                setThemeName
            }
        },
    })
</script>

<style lang='less' scoped>

</style>