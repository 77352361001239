<template>
  <a-radio class="cgs-design-radio" v-model:checked="showChecked" :value="value" :autofocus="autofocus" :disabled="disabled" >
    <slot></slot>
  </a-radio>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from "vue";
export default defineComponent({
  name: "cgsRadio",
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: undefined
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const showChecked = computed({
      get: () => props.checked,
      set: (val) => {
        ctx.emit('update:checked', val)
      }
    })
    const changeHandler = (e) => {
      ctx.emit('change', e)
    }
    return{
      slots,
      showChecked,
      changeHandler
    }
  }
})
</script>

<style lang="less">
@import '~@style/less/theme.less';
.cgs-design-radio.ant-radio-wrapper{
  .text-1
}
.cgs-design-radio .ant-radio-checked .ant-radio-inner{
  border-color: @primary-color;
}
.cgs-design-radio .ant-radio-inner::after{
  background-color: @primary-color;
}
.cgs-design-radio .ant-radio-inner:hover{
  border-color: @primary-color;
}
.cgs-design-radio.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
  border-color: @primary-color;
}
.cgs-design-radio .ant-radio-inner{
  border-color: @border-color;
}
.cgs-design-radio.ant-radio-wrapper{
  color: @font-color-2;
}
</style>
