<!--
*@description 
*@author 杜超峰
*@date 2023/08/08 14:37:52
!-->

<template>
  <cgs-modal :style="{ width: '300px', top: `${top}px`, marginLeft: `${left}px` }" class="cgs-design-select-theme"
    :closable="false" :mask="false">
    <template #content>
      <div class="cgs-design-select-theme-container" v-if="activeType === '' || activeType === 'theme'">
        <div v-for="themeItem in themeList" :key="themeItem.className" class="cgs-design-select-theme-item-flex">
          <div class="cgs-design-select-theme-item"
            :class="themeItem.className === selectedTheme ? 'cgs-design-select-theme-item-noHover' : ''"
            :style="{ background: `rgba(${themeItem.color.r}, ${themeItem.color.g}, ${themeItem.color.b}, ${themeItem.color.a})` }"
            @click="selectTheme(themeItem)">
            <div class="cgs-design-select-theme-color-block">
              <span class="csglogoIconfont logoIcon-duihao" v-if="themeItem.className === selectedTheme"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="cgs-design-select-theme-icon-container" v-if="activeType === '' || activeType === 'icon'">
        <div v-for="icon in iconList" :key="icon.iconName" class="cgs-design-select-theme-icon-item-flex"
          :class="selectedIcon === icon.iconName ? 'cgs-design-select-theme-selected-icon' : ''"
          @click="selectIcon(icon)">
          <span :class="[icon.icon_profix + icon.iconName, fontFamily]"
            class="cgs-design-select-theme-appLogoIcon"></span>
        </div>
      </div>
    </template>
    <template #footer>
    </template>
  </cgs-modal>
</template>

<script>
import {
  computed,
  defineComponent,
  // ref
} from 'vue'
import {
  logoIconList,
  profix,
  font_family,
  defaultIcon_app,
  themeAllList
} from "./config"
import cgsModal from '@components/modal/index.vue'
// import './logoIcon/iconfont.css'

/* eslint-disable */
export default defineComponent({
  name: "cgsSelectThemeIcon",
  props: {
    themeClassName: String,
    iconName: String,
    activeType: {
      type: String,
      default: ''
    },
    top: {
      type: Number,
      default: 50
    },
    left: {
      type: Number,
      default: 50
    },
    // visible: {
    //   type: Boolean,
    //   default: false
    // },
  },
  components: { cgsModal },
  emits: ["themeChanged", "iconChanged", "update:themeClassName", "update:iconName"],
  // const visible = props.visible
  setup (props, ctx) {
    // const emits = defineEmits(["themeChanged", "iconChanged", "update:themeClassName", "update:iconName"])
    const fontFamily = font_family
    const appDefaultIcon = defaultIcon_app
    const themeList = themeAllList
    // const visible = ref(false)
    const selectedTheme = computed({
      get: () => {
        return props.themeClassName ? props.themeClassName : 'defaultTheme'
      },
      set: (newVal) => {
        ctx.emit("update:themeClassName", newVal);
        ctx.emit("themeChanged", newVal);
      }
    })
    const selectedIcon = computed({
      get: () => {
        return props.iconName ? props.iconName : appDefaultIcon
      },
      set: (newVal) => {
        ctx.emit("update:iconName", newVal);
        ctx.emit("iconChanged", newVal);
      }
    })
    const iconList = computed(() => {
      return logoIconList.map(icon => {
        return {
          iconName: icon.font_class,
          icon_profix: profix
        }
      })
    })
    const selectTheme = (theme) => {
      if (theme.className !== selectedTheme.value) {
        selectedTheme.value = theme.className
      }
    }
    const selectIcon = (icon) => {
      if (icon.iconName !== selectedIcon.value) {
        selectedIcon.value = icon.iconName
      }
    }
    return {
      fontFamily,
      appDefaultIcon,
      themeList,
      selectedTheme,
      selectedIcon,
      iconList,
      selectTheme,
      selectIcon,
      // visible
    }
  }

})
</script>

<style>
.cgs-design-select-theme.cgs-design-modal .ant-modal-body {
  padding: 10px;
}

.cgs-design-select-theme.cgs-design-modal .ant-modal-footer {
  display: none;
}

.cgs-design-select-theme .cgs-design-select-theme-icon-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  flex-wrap: wrap;
  max-height: 230px;
  overflow-y: auto;
}
</style>

<style scoped lang="less">
@import "~@style/less/theme.less";

/* .cgs-design-select-theme{
    position: absolute;
    background: #fff;
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 5px 5px #333;
} */
.cgs-design-select-theme .cgs-design-select-theme-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 75px;
  overflow-y: auto;
}

.cgs-design-select-theme .cgs-design-select-theme-item {
  /* display: inline-block; */
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}

.cgs-design-select-theme .cgs-design-select-theme-item-flex {
  /* flex: 1; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-pack: center; */
  -ms-flex-pack: center;
  justify-content: center;
  width: 30px;
  line-height: 36px;
  height: 36px;
  text-align: center;
  margin-right: 3px;
  border-radius: 3px;
  cursor: pointer;
  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  align-items: center;

}

.cgs-design-select-theme .cgs-design-select-theme-appLogoIcon {
  font-size: 20px;
  color: #9e9e9e;
}

.cgs-design-select-theme .cgs-design-select-theme-icon-item-flex {
  width: 36px;
  line-height: 36px;
  height: 36px;
  text-align: center;
  margin-right: 3px;
  border-radius: 3px;
  cursor: pointer;
  align-items: center;
}

.cgs-design-select-theme .cgs-design-select-theme-item:hover:not(.cgs-design-select-theme-item-noHover) .cgs-design-select-theme-color-block {
  background: rgba(0, 0, 0, 0.3)
}

.cgs-design-select-theme .cgs-design-select-theme-color-block {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  line-height: 24px;
}

.cgs-design-select-theme .cgs-design-select-theme-selected-icon {
  background-color: @primary-color
}

.cgs-design-select-theme .cgs-design-select-theme-selected-icon .cgs-design-select-theme-appLogoIcon {
  color: #fff
}

.cgs-design-select-theme .cgs-design-select-theme-icon-item-flex:hover:not(.cgs-design-select-theme-selected-icon) {
  background-color: rgba(0, 0, 0, 0.1)
}
</style>