<template>
  <a-popover :align="align" :arrowPointAtCenter="arrowPointAtCenter" :autoAdjustOverflow="autoAdjustOverflow"
    :color="color" :destroyTooltipOnHide="destroyTooltipOnHide" :getPopupContainer="getPopupContainer"
    :mouseEnterDelay="mouseEnterDelay" :mouseLeaveDelay="mouseLeaveDelay" :overlayClassName="overlayClassName"
    :overlayStyle="overlayStyle" :placement="placement" :trigger="trigger">
    <template #content v-if="'content' in slots">
      <slot name="content"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-popover>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsPopover",
  props: {
    align: {
      type: Object,
      default: undefined
    },
    arrowPointAtCenter: {
      type: Boolean,
      default: false
    },
    autoAdjustOverflow: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: undefined
    },
    defaultVisible: {
      type: Boolean,
      default: false
    },
    destroyTooltipOnHide: {
      type: Boolean,
      default: false
    },
    getPopupContainer: {
      type: Function,
      default: () => document.body
    },
    mouseEnterDelay: {
      type: Number,
      default: 0.1
    },
    mouseLeaveDelay: {
      type: Number,
      default: 0.1
    },
    overlayClassName: {
      type: String,
      default: undefined
    },
    overlayStyle: {
      type: Object,
      default: undefined
    },
    placement: {
      type: String,
      default: "top"
    },
    trigger: {
      type: String,
      default: "hover"
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  setup (props, ctx) {
    const slots = useSlots();
    const showVisible = computed({
      get: () => props.visible,
      set: (val) => {
        ctx.emit('update:visible', val)
      }
    })
    return {
      slots,
      showVisible
    }
  }
})
</script>

<style scoped></style>
