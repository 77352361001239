<template>
  <a-timeline :mode="mode" :reverse="reverse">
    <template #pending v-if="'pending' in slots">
      <slot name="pending"></slot>
    </template>
    <template #pendingDot v-if="'pendingDot' in slots">
      <slot name="pendingDot"></slot>
    </template>

    <slot></slot>
  </a-timeline>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsTimeline",
  props: {
    mode: {
      type: String, //	left | alternate | right
      default: 	undefined
    },
    reverse: {
      type: Boolean,
      default: false
    },
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
