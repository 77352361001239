import load from "./loading"
import message from "./message"
import confirm from "./confirm"

export default {
    components: [load, message, confirm],
    install(Vue) {
        this.components.forEach(component => {
            component.install(Vue)
        })
    }
};