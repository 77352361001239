<template>
  <a-radio-group class="cgs-design-raido-group" v-model:value="showValue" :buttonStyle="buttonStyle" :disabled="disabled" :name="name" :options="options"
           :optionType="optionType" :size="size" @change="changeHandler" @showValue="showValue">
    <slot></slot>
  </a-radio-group>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from "vue";
export default defineComponent({
  name: "cgsRadioGroup",
  props: {
    buttonStyle: {
      type: String,
      default: "outline"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: undefined
    },
    options: {
      type: Array,
      default: undefined
    },
    optionType: {
      type: String,
      default: "default"
    },
    size: {
      type: String,
      default: "default"
    },
    value: {
      type: [String, Number],
      default: undefined
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const changeHandler = (e) => {
      console.log(e)
      ctx.emit('change', e)
    }
    const showValue = computed({
      get: () => props.value,
      set: (val) => {
        ctx.emit('update:value', val)
      }
    })
    return{
      slots,
      changeHandler,
      showValue
    }
  }
})
</script>

<style lang="less">
@import '~@style/less/theme.less';
.cgs-design-raido-group .ant-radio-wrapper{
  .text-1
}
.cgs-design-raido-group .ant-radio-checked .ant-radio-inner{
  border-color: @primary-color;
}
.cgs-design-raido-group .ant-radio-inner::after{
  background-color: @primary-color;
}
.cgs-design-raido-group .ant-radio-inner:hover{
  border-color: @primary-color;
}
.cgs-design-raido-group.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
  border-color: @primary-color;
}
.cgs-design-raido-group .ant-radio-inner{
  border-color: @border-color;
}
.cgs-design-raido-group .ant-radio-wrapper{
  color: @font-color-2;
}
</style>
