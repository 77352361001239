<template>
  <a-avatar-group :maxCount="maxCount" :maxPopoverPlacement="maxPopoverPlacement" :maxPopoverTrigger="maxPopoverTrigger"
                  :maxStyle="maxStyle" :size="size">
    <slot></slot>
  </a-avatar-group>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsAvatarGroup",
  props: {
    maxCount: {
      type: Number,
      default: undefined
    },
    maxPopoverPlacement: {
      type: String,
      default: 'top'
    },
    maxPopoverTrigger: {
      type: String,
      default: 'hover'
    },
    maxStyle: {
      type: Object,
      default: undefined
    },
    size: {
      type: [Number,String,Object],
      default: 'default'
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
