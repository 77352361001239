<!--
*@description
*@author 杜超峰
*@date 2023/04/24 15:36:36
!-->

<template>
    <a-table class="cgs-design-table"
        :class="[modeClassMap[mode], backgroundTransparent ? 'cgs-design-table-background-transparent' : '']"
        :bordered="bordered" :childrenColumnName="childrenColumnName" :columns="columns" :components="components"
        :customHeaderRow="customHeaderRow" :customRow="customRow" :dataSource="dataSource"
        :defaultExpandAllRows="defaultExpandAllRows" :defaultExpandedRowKeys="defaultExpandedRowKeys"
        v-model:expandedRowKeys="showExpandedRowKeys" :expandFixed="expandFixed" :expandRowByClick="expandRowByClick"
        :getPopupContainer="getPopupContainer" :indentSize="indentSize" :loading="loading" :locale="locale"
        :pagination="pagination" :rowClassName="rowClassName" :rowExpandable="rowExpandable" :rowKey="rowKey"
        :rowSelection="rowSelection" :scroll="scroll" :showExpandColumn="showExpandColumn" :showHeader="showHeader"
        :showSorterTooltip="showSorterTooltip" :size="size" :sortDirections="sortDirections" :sticky="sticky"
        :tableLayout="tableLayout" :transformCellText="transformCellText" @change="changeHandler"
        @expand="expandHandler" @expandedRowsChange="expandedRowsChangeHandler" @resizeColumn="resizeColumnHandler">

        <template #bodyCell="{ text, record, index, column }" v-if="'bodyCell' in slots">
            <slot name="bodyCell" :text="text" :record="record" :index="index" :column="column"></slot>
        </template>
        <template #customFilterDropdown="FilterDropdownProps" v-if="'customFilterDropdown' in slots">
            <slot name="customFilterDropdown" :FilterDropdownProps="FilterDropdownProps"></slot>
        </template>
        <template #customFilterIcon="{ filtered, column }" v-if="'customFilterIcon' in slots">
            <slot name="customFilterIcon" :filtered="filtered" :column="column"></slot>
        </template>
        <template #emptyText v-if="'emptyText' in slots">
            <slot name="emptyText"></slot>
        </template>
        <template #expandedRowRender="{ record, index, indent, expanded }" v-if="'expandedRowRender' in slots">
            <slot name="expandedRowRender" :record="record" :index="index" :indent="indent" :expanded="expanded"></slot>
        </template>
        <template #expandIcon="props" v-if="'expandIcon' in slots">
            <slot name="expandIcon" :props="props"></slot>
        </template>
        <template #footer="currentPageData" v-if="'footer' in slots">
            <slot name="footer" :currentPageData="currentPageData"></slot>
        </template>
        <template #headerCell="{ title, column }" v-if="'headerCell' in slots">
            <slot name="headerCell" :title="title" :column="column"></slot>
        </template>
        <template #summary v-if="'summary' in slots">
            <slot name="summary"></slot>
        </template>
        <template #title="{ currentPageData }" v-if="'title' in slots">
            <slot name="title" :currentPageData="currentPageData"></slot>
        </template>
    </a-table>
</template>

<script>
import {
    computed,
    defineComponent,
    useSlots
} from 'vue'

export default defineComponent({
    name: 'cgsTable',
    props: {
        backgroundTransparent: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'default' //default  portal
        },
        bordered: {
            type: Boolean,
            default: false
        },
        childrenColumnName: {
            type: String,
            default: 'children'
        },
        columns: {
            type: Array,
            default: undefined
        },
        components: {
            type: Object,
            default: undefined
        },
        customHeaderRow: {
            type: Function,
            default: undefined
        },
        customRow: {
            type: Function,
            default: undefined
        },
        dataSource: {
            type: Array,
            default: () => []
        },
        defaultExpandAllRows: {
            type: Boolean,
            default: false
        },
        defaultExpandedRowKeys: {
            type: Array,
            default: () => []
        },
        expandedRowKeys: {
            type: Array,
            default: undefined
        },
        expandFixed: {
            type: [Boolean, String],
            default: false
        },
        expandRowByClick: {
            type: Boolean,
            default: false
        },
        getPopupContainer: {
            type: Object,
            default: undefined
        },
        indentSize: {
            type: Number,
            default: 15
        },
        loading: {
            type: [Boolean, Object],
            default: false
        },
        locale: {
            type: Object,
            default: undefined
            //   () => {
            //     return {
            //         filterConfirm: '确定',
            //         filterReset: '重置',
            //         emptyText: '暂无数据'
            //     }
            // }
        },
        pagination: {
            type: [Object, Boolean],
            default: undefined
        },
        rowClassName: {
            type: Function,
            default: undefined
        },
        rowExpandable: {
            type: Function,
            default: undefined
        },
        getPopuprowExpandableContainer: {
            type: Object,
            default: undefined
        },
        rowKey: {
            type: [String, Function],
            default: 'key'
        },
        rowSelection: {
            type: Object,
            default: null
        },
        scroll: {
            type: Object,
            default: undefined
        },
        showExpandColumn: {
            type: Boolean,
            default: true
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showSorterTooltip: {
            type: [Function],
            default: () => { }
        },
        size: {
            type: String,
            default: 'default'
        },
        sortDirections: {
            type: Array,
            default: () => ['ascend', 'descend']
        },
        sticky: {
            type: [Boolean, Function],
            default: undefined
        },
        tableLayout: {
            type: String,
            default: ''
        },
        transformCellText: {
            type: Function,
            default: undefined
        }
    },

    setup (props, ctx) {
        let modeClassMap = {
            default: '',
            portal: 'cgs-design-table-portal-mode'
        }
        const slots = useSlots();
        const changeHandler = (pagination, filters, sorter, currentDataSourceObj) => {
            ctx.emit('change', pagination, filters, sorter, currentDataSourceObj)
        }
        const expandHandler = (expanded, record) => {
            ctx.emit('expand', expanded, record)
        }
        const expandedRowsChangeHandler = (expandedRows) => {
            ctx.emit('expandedRowsChange', expandedRows)
        }
        const resizeColumnHandler = (width, column) => {
            ctx.emit('resizeColumn', width, column)
        }
        let showExpandedRowKeys = computed({
            get: () => props.expandedRowKeys,
            set: (val) => {
                ctx.emit('update:expandedRowKeys', val)
            }
        })
        return {
            modeClassMap,
            showExpandedRowKeys,
            changeHandler,
            expandHandler,
            expandedRowsChangeHandler,
            resizeColumnHandler,
            slots
        }
    },
})
</script>

<style lang='less'>
@import '~@style/less/theme.less';

.cgs-design-table.cgs-design-table-portal-mode .ant-table {
    color: @font-color-1;
}

.cgs-design-table.cgs-design-table-portal-mode .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px;
}

.cgs-design-table.cgs-design-table-background-transparent .ant-table {
    background: transparent;
}

.cgs-design-table.cgs-design-table-background-transparent .ant-table-thead>tr>th {
    background: transparent;
}

.cgs-design-table.cgs-design-table-background-transparent .ant-table-cell-fix-left,
.ant-table-cell-fix-right {
    background: transparent;
}

.cgs-design-table.cgs-design-table-portal-mode .ant-table-tbody>tr>td {
    border-bottom-width: 0px;
}

.cgs-design-table.cgs-design-table-portal-mode .ant-table-thead>tr>th {
    // border-bottom-color:
    .border(0, 0, 1px, 0)
}

.cgs-design-table.cgs-design-table-portal-mode .ant-table-tbody>tr.ant-table-row:hover>td,
.cgs-design-table.cgs-design-table-portal-mode .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: @selected-color;
}

.cgs-design-table.cgs-design-table-portal-mode .ant-table-tbody>tr.ant-table-row:hover>td:first-child,
.cgs-design-table.cgs-design-table-portal-mode .ant-table-tbody>tr>td.ant-table-cell-row-hover:first-child {
    border-radius: 5px 0px 0px 5px;
}

.cgs-design-table.cgs-design-table-portal-mode .ant-table-tbody>tr.ant-table-row:hover>td:last-child,
.cgs-design-table.cgs-design-table-portal-mode .ant-table-tbody>tr>td.ant-table-cell-row-hover:last-child {
    border-radius: 0px 5px 5px 0px;
}

.cgs-design-table.cgs-design-table-portal-mode .ant-table-thead>tr>th {
    color: @font-color-2;
}

.cgs-design-table tr.ant-table-expanded-row>td {
    background: transparent;
}

.cgs-design-table tr.ant-table-expanded-row:hover>td {
    background: @selected-color;
}

.cgs-design-table .ant-table-row-expand-icon:focus,
.cgs-design-table .ant-table-row-expand-icon:hover {
    color: @primary-color;
    background: @selected-color;
}

.cgs-design-table .ant-table-row-expand-icon:active {
    color: @primary-color;
}

.cgs-design-table .ant-table-row-expand-icon {
    background: @card-color;
}

.cgs-design-table .ant-pagination-item-active,
.cgs-design-table .ant-pagination-item-active:hover {
    .border;
    color: @font-color-1;
    background: @selected-color;
}

.cgs-design-table .ant-pagination-item-active a,
.cgs-design-table .ant-pagination-item-active:hover a {
    color: @font-color-1;
}

.cgs-design-table .ant-pagination-item:hover {
    border: 0;
    background: @selected-color;
}

.cgs-design-table .ant-pagination-item a,
.cgs-design-table .ant-pagination-item:hover a {
    color: @font-color-1;
}

.cgs-design-table .ant-pagination-item {
    background: @card-color;
    .border
}

.cgs-design-table .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.cgs-design-table .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: @font-color-3;
}

.cgs-design-table .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: @card-color;
    color: @font-color-1;
    .border
}

.cgs-design-table .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    .border
}

.cgs-design-table .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    .card-box-shadow;
    .border
}

.cgs-design-table .ant-pagination-prev:hover .ant-pagination-item-link,
.cgs-design-table .ant-pagination-next:hover .ant-pagination-item-link {
    .border;
    color: @font-color-1;
    background: @selected-color;
}

.cgs-design-table .ant-pagination-prev .ant-pagination-item-link,
.cgs-design-table .ant-pagination-next .ant-pagination-item-link {
    .border;
    background: @card-color;
}

.cgs-design-table .anticon svg {
    color: @font-color-1;
}
</style>
