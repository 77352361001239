<template>
  <a-auto-complete v-model:value="showValue" :allowClear="allowClear" :autofocus="autofocus" :backfill="backfill"
          :defaultActiveFirstOption="defaultActiveFirstOption" :defaultOpen="defaultOpen" :disabled="disabled"
          :dropdownMatchSelectWidth="dropdownMatchSelectWidth" :dropdownMenuStyle="dropdownMenuStyle" :filterOption="filterOption"
          :open="open" :options="options" @blurHandler="blurHandler" @changeHandler="changeHandler"
          @dropdownVisibleChangeHandler="dropdownVisibleChangeHandler" @focusHandler="focusHandler" @searchHandler="searchHandler"
          @selectHandler="selectHandler">
    <template #default v-if="'default' in slots">
      <slot name="default"></slot>
    </template>
    <template #option v-if="'option' in slots">
      <slot name="option"></slot>
    </template>
    <template #placeholder v-if="'placeholder' in slots">
      <slot name="placeholder"></slot>
    </template>
    <slot></slot>
  </a-auto-complete>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from "vue";
export default defineComponent({
  name: "cgsAutoComplete",
  props: {
    allowClear: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    backfill: {
      type: Boolean,
      default: false
    },
    defaultActiveFirstOption: {
      type: Boolean,
      default: true
    },
    defaultOpen: {
      type: Boolean,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dropdownMatchSelectWidth: {
      type: [Boolean, Number],
      default: true
    },
    dropdownMenuStyle: {
      type: Object,
      default: undefined
    },
    filterOption: {
      type: [Boolean, Function],
      default: true
    },
    open: {
      type: Object,
      default: undefined
    },
    options: {
      type: Array,
      default: undefined
    },
    value: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  setup(props, ctx){
    const slots = useSlots();
    const blurHandler = () => {
      ctx.emit('blur')
    }
    const changeHandler = (value) => {
      ctx.emit('change', value)
    }
    const dropdownVisibleChangeHandler = (open) => {
      ctx.emit('dropdownVisibleChange', open)
    }
    const focusHandler = () => {
      ctx.emit('focus')
    }
    const searchHandler = (value) => {
      ctx.emit('search', value)
    }
    const selectHandler = (value, option) => {
      ctx.emit('select', value, option)
    }
    const showValue = computed({
      get: () => props.value,
      set: (val) => {
        ctx.emit('update:value', val)
      }
    })
    return{
      slots,
      blurHandler,
      changeHandler,
      dropdownVisibleChangeHandler,
      focusHandler,
      searchHandler,
      selectHandler,
      showValue
    }
  }
})
</script>

<style scoped>

</style>
