<template>
  <a-notification :bottom="bottom" :btn="btn" :class="'class'" :close-icon="closeIcon" :description="description"
                  :duration="duration" :get-container="getContainer" :icon="icon" :key="key" :message="message"
                  :placement="placement" :style="style" :top="top" :on-click="onClick" :on-close="onClose"
                  :max-count="maxCount" :rtl="rtl">
    <slot></slot>
  </a-notification>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsNotification",
  props: {
    bottom: {
      type: String,
      default: '24px'
    },
    btn: {
      type: [Object, Function],
      default: undefined
    },
    class: {
      type: String,
      default: undefined
    },
    closeIcon: {
      type: [Object, Function],
      default: undefined
    },
    description: {
      type: [String, Object, Function],
      default: undefined
    },
    duration: {
      type: Number,
      default: 4.5
    },
    getContainer: {
      type: Function,
      default: () => document.body
    },
    icon: {
      type: [Object, Function],
      default: undefined
    },
    // eslint-disable-next-line vue/no-reserved-props
    key: {
      type: String,
      default: undefined
    },
    message: {
      type: [String, Object, Function],
      default: undefined
    },
    placement: {
      type: String,
      default: 'topRight'
    },
    style: {
      type: [Object, String],
      default: undefined
    },
    top: {
      type: String,
      default: '24px'
    },
    onClick: {
      type: Function,
      default: undefined
    },
    onClose: {
      type: Function,
      default: undefined
    },
    maxCount: {
      type: Number,
      default: 3,
    },
    rtl: {
      type: Boolean,
      default: false
    }
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
