<!--
*@description 
*@author 杜超峰
*@date 2023/04/25 18:16:48
!-->

<template>
    <cgs-drop-down class="cgs-desgin-more" overlayClassName="cgs-design-more-overlay">
        <slot name="triggerButton" v-if="'triggerButton' in slots"></slot>
        <span class="cgs-design-more-icon" :class="iconModeClassMap[moreIconColorMode]" v-else>
            <more-outlined v-if="vertical" />
            <ellipsis-outlined v-else />
        </span>

        <template #overlay>
            <cgs-menu class="cgs-design-more-menu" @click="clickHandler">
                <cgs-menu-item v-for="item in menuList" :key="item.key" :menu-key="item.key">
                    <div>
                        <span class="cgs-design-more-menu-item-icon" :class="item.icon"></span>
                        <span>{{ item.title }}</span>
                    </div>
                </cgs-menu-item>
            </cgs-menu>
        </template>
    </cgs-drop-down>
</template>

<script>
    import {
        defineComponent,
        useSlots
    } from 'vue'
    import cgsDropDown from '@components/dropDown/index.vue'
    import cgsMenu from '@components/menu/index.vue'
    import cgsMenuItem from '@components/menuItem/index.vue'
    import {
        EllipsisOutlined,
        MoreOutlined
    } from '@ant-design/icons-vue'


    export default defineComponent({
        name: 'cgsMore',
        components: {
            cgsDropDown,
            cgsMenu,
            cgsMenuItem,
            EllipsisOutlined,
            MoreOutlined
        },
        props: {
            vertical: {
                type: Boolean,
                default: false
            },
            menuList: {
                type: Array,
                default: () => []
            },
            moreIconColorMode: {
                type: String,
                default: 'default' //default dark light
            }
        },

        setup(props, ctx) {
            const slots = useSlots();
            const iconModeClassMap = {
                default: '',
                dark: 'cgs-desgin-more-icon-dark',
                light: 'cgs-desgin-more-icon-light'
            }
            const clickHandler = (params) => {
                ctx.emit('clikMenuItem', params)
            }
            return {
                iconModeClassMap,
                clickHandler,
                slots
            }
        },
    })
</script>

<style lang='less'>
    @import '~@style/less/theme.less';

    .cgs-design-more-icon.cgs-desgin-more {
        color: @font-color-1;
        cursor: pointer;
    }

    .cgs-design-more-icon.cgs-desgin-more:hover,
    .cgs-design-more-icon.cgs-desgin-more.cgs-desgin-more-icon-dark:hover,
    .cgs-design-more-icon.cgs-desgin-more.cgs-desgin-more-icon-light:hover {
        color: @primary-color;
    }

    .cgs-design-more-icon.cgs-desgin-more.cgs-desgin-more-icon-dark {
        color: @foreground-color-dark-background;
    }

    .cgs-design-more-icon.cgs-desgin-more.cgs-desgin-more-icon-light {
        color: @foreground-color-light-background;
    }

    .cgs-design-more-overlay.ant-dropdown {
        padding: 8px;
        background: @card-color;
        .border();
        .card-box-shadow();
    }

    .cgs-design-more-overlay .ant-dropdown-menu {
        background-color: transparent;
        box-shadow: unset;
    }

    .cgs-design-more-overlay .cgs-design-menu.cgs-design-more-menu .ant-dropdown-menu-item,
    .cgs-design-more-overlay .cgs-design-menu.cgs-design-more-menu .ant-dropdown-menu-submenu-title {
        color: @font-color-1;
    }

    .cgs-design-more-overlay .cgs-design-menu.cgs-design-more-menu .ant-dropdown-menu-item:hover,
    .cgs-design-more-overlay .cgs-design-menu.cgs-design-more-menu .ant-dropdown-menu-submenu-title:hover {
        background: @selected-color;
        border-radius: 2px;
    }

    .cgs-design-more-menu-item-icon {
        margin-right: 8px;
    }
</style>