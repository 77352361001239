<template>
  <a-badge-ribbon :color="color" :placement="placement">
    <template #text v-if="'text' in slots">
      <slot name="text"></slot>
    </template>
    <slot></slot>
  </a-badge-ribbon>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsBadgeRibbon",
  props: {
    color: {
      type: String,
      default: undefined
    },
    placement: {
      type: String, //start | end
      default: 'end'
    },
  },
  setup(){
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
