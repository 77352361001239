<template>
  <a-statistic-countdown :value="value" :format="format" :valueStyle="valueStyle" @finishiHandler="finishHandler">
    <template #prefix v-if="'prefix' in slots">
      <slot name="prefix"></slot>
    </template>
    <template #suffix v-if="'suffix' in slots">
      <slot name="suffix"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-statistic-countdown>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsStatisticCountdown",
  props: {
    format: {
      type: String,
      default: 	'HH:mm:ss'
    },
    value: {
      type: [Number,Object],
      default: undefined
    },
    valueStyle: {
      type: Object,
      default: undefined
    }
  },
  setup(props, ctx){
    const slots = useSlots();
    const finishHandler = () => {
      ctx.emit('finish')
    }
    return{
      slots,
      finishHandler
    }
  }
})
</script>

<style scoped>

</style>
