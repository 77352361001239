<template>
  <a-checkbox-group class="cgs-design-checkbox-group" v-model:value="showValue" :name="name" :disabled="disabled"
    :options="options" @changeHandler="changeHandler">
    <template #label="{label, value, disabled, indeterminate, onChange}" v-if="'label' in slots">
      <slot name="label" :label="label" :value="value" :disabled="disabled" :indeterminate="indeterminate" :onChange="onChange"></slot>
    </template>
    <slot></slot>
  </a-checkbox-group>
</template>

<script>
  import {
    computed,
    defineComponent,
    useSlots
  } from "vue";
  export default defineComponent({
    name: "cgsCheckboxGroup",
    props: {
      name: {
        type: String,
        default: undefined
      },
      disabled: {
        type: Boolean,
        default: false
      },
      options: {
        type: Array,
        default: undefined
      },
      value: {
        type: Array,
        default: undefined
      }
    },
    setup(props, ctx) {
      const slots = useSlots();
      const changeHandler = (e) => {
        ctx.emit('change', e)
      }
      const showValue = computed({
        get: () => props.value,
        set: (val) => {
          ctx.emit('update:value', val)
        }
      })
      return {
        slots,
        changeHandler,
        showValue
      }
    }
  })
</script>

<style lang="less">
  @import '~@style/less/theme.less';

  .cgs-design-checkbox-group.ant-checkbox-group,
  .cgs-design-checkbox-group .ant-checkbox-wrapper {
    color: @font-color-1;
  }

  .cgs-design-checkbox-group .ant-checkbox-inner {
    background: transparent;
    .border();
  }

  .cgs-design-checkbox-group.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .cgs-design-checkbox-group .ant-checkbox:hover .ant-checkbox-inner,
  .cgs-design-checkbox-group .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: @primary-color;
  }

  .cgs-design-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
    background: @primary-color;
    border-color: @primary-color;
  }
</style>