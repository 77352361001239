<template>
  <a-transfer v-model:selectedKeys="showSelectedKeys" v-model:targetKeys="showTargetKeys" :dataSource="dataSource" :disabled="disabled" @scrollHandler="scrollHandler" @searchHandler="searchHandler" @changeHandler="changeHandler"
              @selectChangeHandler="selectChangeHandler" :filterOption="filterOption" :listStyle="listStyle" :locale="locale" :oneWay="oneWay"
              :operations="operations" :operationStyle="operationStyle" :pagination="pagination" :showSearch="showSearch" :showSelectAll="showSelectAll"
              :titles="titles">
    <template #footer="footer" v-if="'footer' in slots">
      <slot name="footer" :footer="footer"></slot>
    </template>
  <template #render v-if="'render' in slots">
    <slot name="render"></slot>
  </template>
    <slot></slot>
  </a-transfer>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsTransfer",
  props: {
    dataSource: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterOption: {
      type: Boolean,
      default: undefined
    },
    listStyle: {
      type: Object,
      default: undefined
    },
    locale: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: undefined//{ itemUnit: '项', itemsUnit: '项', notFoundContent: '列表为空', searchPlaceholder: '请输入搜索内容' }
    },
    oneWay: {
      type: Boolean,
      default: false
    },
    operations: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: ['>', '<']
    },
    operationStyle: {
      type: Object,
      default: undefined
    },
    pagination: {
      type: [Boolean,Object],
      default: false
    },
    selectedKeys: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    showSelectAll: {
      type: Boolean,
      default: true
    },
    targetKeys: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    titles: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: 	['', '']
    },
    value: {
      type: [Number, Array],
      default: undefined
    },
    vertical: {
      type: Boolean,
      default: false
    },
  },
  setup(props, ctx){
    const slots = useSlots();
    const scrollHandler = (direction, event) => {
      ctx.emit('scroll',direction, event)
    }
    const searchHandler = (direction,value) => {
      ctx.emit('search',direction,value)
    }
    const changeHandler = (targetKeys, direction, moveKeys) => {
      ctx.emit('change', targetKeys, direction, moveKeys)
    }
    const selectChangeHandler = (sourceSelectedKeys, targetSelectedKeys) => {
      ctx.emit('selectChange', sourceSelectedKeys, targetSelectedKeys)
    }
    const showSelectedKeys = computed({
      get: () => props.selectedKeys,
      set: (val) => {
        ctx.emit('update:selectedKeys', val)
      }
    })
    const showTargetKeys = computed({
      get: () => props.targetKeys,
      set: (val) => {
        ctx.emit('update:targetKeys', val)
      }
    })
    return{
      slots,
      scrollHandler,
      searchHandler,
      changeHandler,
      selectChangeHandler,
      showSelectedKeys,
      showTargetKeys
    }
  }
})
</script>

<style scoped>

</style>
