<template>
  <a-timeline-item :color="color" :position="position">
    <template #dot v-if="'dot' in slots">
      <slot name="dot"></slot>
    </template>
    <template #label v-if="'label' in slots">
      <slot name="label"></slot>
    </template>

    <slot></slot>
  </a-timeline-item>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsTimelineItem",
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    position: {
      type: String, //	left | right
      default: undefined
    },
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
