<!--
*@description 
*@author 杜超峰
*@date 2023/04/25 19:45:49
!-->

<template>
  <a-menu-item class="cgs-design-menu-item" v-bind="attrs" :key="menuKey">
    <template v-slot:[name] v-for="(_, name) in slots">
        <slot :name='name'></slot>
      </template>
    <slot></slot>
  </a-menu-item>
</template>

<script>
  import {
    defineComponent,
    useAttrs,
    useSlots
  } from 'vue'

  export default defineComponent({
    name: "cgsMenuItem",
    components: {},
    props: {
      menuKey: {
        type: [String, Number],
        default: ''
      }
    },

    setup() {
      const slots = useSlots()
      const attrs = useAttrs()
      return {
        slots,
        attrs
      }
    },
  })
</script>

<style lang='less' scoped>

</style>