// vue3 导入方式
import {
    createApp,
} from 'vue'
// loading 组件导入
import myLoad from './myLoading'
// const msg = reactive({
//     show: false,
//     title: '拼命加载中...'
// })
// let instance = null
// let parent = null
// let times = 0

// const $loading = createApp(myLoad, {
//     msg
// }).mount(document.createElement('div'))
// const load = {
//     open(title) { // 控制显示loading的方法
//         if (times == 0) {
//             instance = createApp(myLoad)
//             parent = document.createElement("div")
//             let appDom = document.getElementById('app')
//             appDom.appendChild(parent)
//             instance.mount(parent)
//         }
//         times++
//     },

//     close() { // 控制loading隐藏的方法
//         times--
//         if (times <= 0) {
//             times = 0
//             let appDom = document.getElementById('app')
//             instance.unmount(parent)
//             appDom.removeChild(parent)
//         }
//     }
// }

// export default {
//     install(app) {
//         // console.log(app);
//         // Vue.prototype.$http = axios
//         app.config.globalProperties.$loading = load
//     }
// }

export default {
    loading: null,
    install(Vue) {
      if (this.loading) {
        // 防止多次载入
        Vue.config.globalProperties.$loading = this.loading;
        return ;
      }
      let instance = createApp(myLoad);
      let parent = document.createElement("div")
      let bodyDom = document.body
      // 这里需要注意，大概率app还没有mount，导致获取不到app节点，所以想挂载到app上，需要保证app已经创建。
      bodyDom.appendChild(parent)
      this.loading = instance.mount(parent)
      Vue.config.globalProperties.$loading = this.loading;
    }
  };