<template>
  <a-input-number class="cgs-design-inputNumber" v-bind="attrs">
    <template v-slot:[name] v-for="(_, name) in slots">
      <slot :name='name'></slot>
    </template>
  </a-input-number>
</template>

<script>
  import {
    defineComponent,
    useAttrs,
    useSlots
  } from 'vue'

  export default defineComponent({
    name: "cgsInputNumber",
    props: {},
    setup() {
      const slots = useSlots();
      const attrs = useAttrs()
      return {
        slots,
        attrs
      }
    }
  })
</script>

<style lang="less">
  @import '~@style/less/theme.less';

  .cgs-design-inputNumber.ant-input-number:hover {
    border: 1px solid @font-color-1;
  }

  .cgs-design-inputNumber.ant-input-number-affix-wrapper {
    // .text-2;
    color: @font-color-1;
    border: 1px solid @selected-color;
    background: @selected-color;
  }

  .cgs-design-inputNumber.ant-input-number-affix-wrapper:focus,
  .cgs-design-inputNumber.ant-input-number-affix-wrapper-focused {
    border: 1px solid @font-color-1;
    .card-box-shadow()
  }

  .cgs-design-inputNumber.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
    border: 1px solid @font-color-1;
  }

  .cgs-design-inputNumber.ant-input-number-affix-wrapper .cgs-design-inputNumber.ant-input-number {
    background: transparent;
    border: 0;
  }

  .cgs-design-inputNumber.ant-input-number {
    .text-2;
    color: @font-color-1;
    border: 1px solid @selected-color;
    background: @selected-color;
  }

  .cgs-design-inputNumber.ant-input-number:focus,
  .ant-input-number-focused {
    border: 1px solid @font-color-1;
    .card-box-shadow()
  }

  .cgs-design-inputNumber.ant-input-number-focused {
    border: 1px solid @font-color-1;
    .card-box-shadow()
  }

  .cgs-design-inputNumber .ant-input-number-handler-wrap {
    background: @selected-color;
  }
</style>