<!--
*@description 
*@author 杜超峰
*@date 2023/04/25 19:45:49
!-->

<template>
  <a-menu-item-group class="cgs-design-menu-item-group" :key="menuKey" :disabled="disabled" :popupClassName="popupClassName"
    :popupOffset="popupOffset" :title="title">
    <template #icon v-if="'icon' in slots">
      <slot name="icon"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-menu-item-group>
</template>

<script>
  import {
    defineComponent,
    useSlots
  } from 'vue'

  export default defineComponent({
    name: "cgsMenuItemGroup",
    components: {},
    props: {
      menuKey: {
        type: [String, Number],
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      popupClassName: {
        type: String,
        default: undefined
      },
      popupOffset: {
        type: Array,
        default: undefined
      },
      title: {
        type: String,
        default: undefined
      }
    },

    setup(props, ctx) {
      const slots = useSlots()
      const titleClickHandler = (params) => {
        ctx.emit('titleClick', params)
      }
      return {
        slots,
        titleClickHandler
      }
    },
  })
</script>

<style lang='less' scoped>
</style>