<template>
  <a-tag v-model:visible="showVisible" :closable="closable" :color="color" :visible="visible" @closeHandler="closeHandler">
    <template #closeIcon v-if="'closeIcon' in slots">
      <slot name="closeIcon"></slot>
    </template>
    <template #icon v-if="'icon' in slots">
      <slot name="icon"></slot>
    </template>
    <slot></slot>
  </a-tag>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsTag",
  props: {
    closable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx){
    const slots = useSlots();
    const closeHandler = (e) => {
      ctx.emit('close', e)
    }
    const showVisible = computed({
      get: () => props.visible,
      set: (val) => {
        ctx.emit('update:visible', val)
      }
    })
    return{
      slots,
      closeHandler,
      showVisible
    }
  }
})
</script>

<style scoped>

</style>
