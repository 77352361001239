<!--
*@description
*@author 杜超峰
*@date 2023/04/19 17:53:46
!-->

<template>
    <a-input-password v-model:value="showValue" :placeholder="placeholder" :allowClear="allowClear" :bordered="bordered"
        :disabled="disabled" :id="id" :maxlength="maxlength" :visibilityToggle="visibilityToggle" @change="changeHandler" @pressEnter="pressEnterHandler">
        <template #addonAfter v-if="'addonAfter' in slots">
            <slot name="addonAfter"></slot>
        </template>
        <template #addonBefore v-if="'addonBefore' in slots">
            <slot name="addonBefore"></slot>
        </template>
        <template #prefix v-if="'prefix' in slots">
            <slot name="prefix"></slot>
        </template>
        <template #suffix v-if="'suffix' in slots">
            <slot name="suffix"></slot>
        </template>
    </a-input-password>
</template>

<script>
    import {
        defineComponent,
        computed,
        useSlots
    } from 'vue'

    export default defineComponent({
        name: "cgsPassword",
        props: {
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            allowClear: {
                type: Boolean,
                default: false
            },
            bordered: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            id: {
                type: [String, undefined],
                default: undefined
            },
            maxlength: {
                type: [Number, undefined],
                default: undefined
            },
            showCount: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: 'default'
            },
            visibilityToggle: {
                type: Boolean,
                default: true
            }
        },

        setup(props, ctx) {
            const slots = useSlots();
            const changeHandler = (e) => {
                ctx.emit('change', e)
            }
            const pressEnterHandler = (e) => {
                ctx.emit('change', e)
            }
            const showValue = computed({
                get: () => props.value,
                set: (val) => {
                    ctx.emit('update:value', val)
                }
            })
            return {
                slots,
                showValue,
                changeHandler,
                pressEnterHandler
            }
        },
    })
</script>

<style scoped>

</style>