<template>
  <a-collapse class="cgs-design-collapse" :accordion="accordion" v-model:activeKey="showActiveKey" :bordered="bordered"
    :collapsible="collapsible" :destroyInactivePanel="destroyInactivePanel" :expandIconPosition="expandIconPosition"
    :ghost="ghost">
    <template #expandIcon v-if="'expandIcon' in slots">
      <slot name="expandIcon"></slot>
    </template>
    <slot></slot>
  </a-collapse>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsCollapse",
  props: {
    accordion: {
      type: Boolean,
      default: false
    },
    activeKey: {
      type: [Array, String],
      default: undefined,
    },
    bordered: {
      type: Boolean,
      default: true
    },
    collapsible: {
      type: [String, Boolean],
      default: undefined,
      // header: allow collapse by clicking the header
      // disabled: disable collapse for all panels
    },
    destroyInactivePanel: {
      type: Boolean,
      default: false
    },
    expandIconPosition: {
      type: String,
      default: 'left',
      // set the position of the expand icon, 'left' or 'right'
    },
    ghost: {
      type: Boolean,
      default: false
    }
  },
  setup (props, ctx) {
    const slots = useSlots();
    const showActiveKey = computed({
      get: () => props.activeKey,
      set: (val) => {
        ctx.emit('update:activeKey', val)
      }
    })
    return {
      slots,
      showActiveKey
    }
  }
})
</script>

<style lang="less">
@import '~@style/less/theme.less';

.cgs-design-collapse.ant-collapse {
  background: rgba(153, 153, 153, 0.3);
  .border(1px, 1px, 1px, 1px)
}

.cgs-design-collapse .ant-collapse-content {
  background-color: @selected-color-08
}

.cgs-design-collapse.ant-collapse>.ant-collapse-item {
  .border(0px, 0px, 1px, 0px)
}

.cgs-design-collapse.ant-collapse .ant-collapse-item .ant-collapse-header {
  color: @font-color-1;
  .text-1
}

.cgs-design-collapse.ant-collapse .ant-collapse-item .ant-collapse-content-box {
  color: @font-color-2;
  .text-2
}
</style>
