<template>
  <a-tooltip v-model:visible="showVisible" :align="align" :arrowPointAtCenter="arrowPointAtCenter" :autoAdjustOverflow="autoAdjustOverflow" :color="color"
             :destroyTooltipOnHide="destroyTooltipOnHide" :getPopupContainer="getPopupContainer"
             :mouseEnterDelay="mouseEnterDelay" :mouseLeaveDelay="mouseLeaveDelay" :overlayClassName="overlayClassName"
             :overlayStyle="overlayStyle" :placement="placement" :trigger="trigger" @visibleChangeHandler="visibleChangeHandler">
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <span @mouseenter="mouseenterHandler">
      <slot></slot>
    </span>
  </a-tooltip>
</template>

<script>
import {
  computed,
  defineComponent, ref,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsTooltip",
  props: {
    align: {
      type: Object,
      default: undefined
    },
    arrowPointAtCenter: {
      type: Boolean,
      default: false
    },
    autoAdjustOverflow: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: undefined
    },
    destroyTooltipOnHide: {
      type: Boolean,
      default: false
    },
    getPopupContainer: {
      type: Function,
      default: () => document.body
    },
    mouseEnterDelay: {
      type: Number,
      default: 0.1
    },
    mouseLeaveDelay: {
      type: Number,
      default: 0.1
    },
    overlayClassName: {
      type: String,
      default: undefined
    },
    overlayStyle: {
      type: Object,
      default: undefined
    },
    placement: {
      type: String,
      default: "top"
    },
    trigger: {
      type: String,
      default: "hover"
    },
    visible: {
      type: Boolean,
      default: undefined
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const vis = ref(false)
    const visibleChangeHandler = (visible) => {
      ctx.emit('visibleChange', visible)
    }
    const showVisible = computed({
      get: () => {
        let _bool = props.visible
        if(props.visible === undefined){
          _bool = vis.value
        }
        return _bool
      },
      set: (val) => {
        if(props.visible === undefined){
          vis.value = val
        } else {
          ctx.emit('update:visible', val)
        }

      }
    })
    const mouseenterHandler = () => {
      showVisible.value = true
    }
    return{
      slots,
      visibleChangeHandler,
      showVisible,
      mouseenterHandler
    }
  }
})
</script>

<style scoped>

</style>
