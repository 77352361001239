<template>
  <a-list-item-meta>
    <template #avatar v-if="'avatar' in slots">
      <slot name="avatar"></slot>
    </template>
    <template #description v-if="'description' in slots">
      <slot name="description"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-list-item-meta>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsListItemMeta",
  props: {
  },
  setup(){
    const slots = useSlots();
    return{
      slots,
    }
  }
})
</script>

<style scoped>

</style>
