<template>
  <a-skeleton :active="active" :avatar="avatar" :loading="loading" :paragraph="paragraph" :title="title">
    <slot></slot>
  </a-skeleton>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsSkeleton",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    avatar: {
      type: [Boolean, Object],
      default: false
    },
    loading: {
      type: Boolean,
      default: undefined
    },
    paragraph: {
      type: [Boolean, Object],
      default: true
    },
    title: {
      type: [Boolean, Object],
      default: true
    },

  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
