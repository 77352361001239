<template>
  <a-spin :delay="delay" :size="size" :spinning="spinning" :wrapperClassName="wrapperClassName">
    <template #indicator v-if="'indicator' in slots">
      <slot name="indicator"></slot>
    </template>
    <template #tip v-if="'tip' in slots">
      <slot name="tip"></slot>
    </template>
    <slot></slot>
  </a-spin>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsSpin",
  props: {
    delay: {
      type: Number,
      default: undefined
    },
    size: {
      type: String,
      default: 'default'
    },
    spinning: {
      type: Boolean,
      default: true
    },
    wrapperClassName: {
      type: String,
      default: undefined
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
