<template>
  <div class="cgs-design-range-picker-container" ref="container">
    <a-range-picker
      class="cgs-design-range-picker"
      :style="{ width: '100%' }"
      v-bind="attrs"
      :getPopupContainer="getContainer"
    >
      <template v-for="(_, name) in slots" #[name]="scopedData">
        <slot :name="name" v-bind="scopedData"></slot>
      </template>
    </a-range-picker>
  </div>
</template>

<script>
import { ref, defineComponent, useSlots, useAttrs } from "vue";

export default defineComponent({
  name: "cgsRangePicker",
  props: {
    isAppendBody: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const slots = useSlots();
    const attrs = useAttrs();
    const container = ref(null);
    const getContainer = () => container.value;
    return {
      slots,
      attrs,
      container,
      getContainer: props.isAppendBody ? null : getContainer,
    };
  },
});
</script>

<style scoped>
.cgs-design-range-picker-container,
.cgs-design-range-picker {
  width: 100%;
}
</style>
