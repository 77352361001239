<template>
  <a-drawer :autofocus="autofocus" :bodyStyle="bodyStyle" :class="'class'" :closable="closable"
            :contentWrapperStyle="contentWrapperStyle" :destroyOnClose="destroyOnClose" :drawerStyle="drawerStyle"
            :footerStyle="footerStyle" :forceRender="forceRender" :getContainer="getContainer" :headerStyle="headerStyle"
            :height="height" :keyboard="keyboard" :mask="mask" :maskClosable="maskClosable" :maskStyle="maskStyle"
            :placement="placement" :push="push" :size="size" :style="style" v-model:visible="showVisible" :width="width" :zIndex="zIndex"
            @afterVisibleChangeHandler="afterVisibleChangeHandler" @closeHandler="closeHandler">
    <template #closeIcon v-if="'closeIcon' in slots">
      <slot name="closeIcon"></slot>
    </template>
    <template #extra v-if="'extra' in slots">
      <slot name="extra"></slot>
    </template>
    <template #footer v-if="'footer' in slots">
      <slot name="footer"></slot>
    </template>
    <template #title v-if="'title' in slots">
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-drawer>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsDrawer",
  props: {
    autofocus: {
      type: Boolean,
      default: true
    },
    bodyStyle: {
      type: Object,
      default: undefined
    },
    classs: {
      type: String,
      default: undefined
    },
    closable: {
      type: Boolean,
      default: true
    },
    contentWrapperStyle: {
      type: Object,
      default: undefined
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    },
    drawerStyle: {
      type: Object,
      default: undefined
    },
    footerStyle: {
      type: Object,
      default: undefined
    },
    forceRender: {
      type: Boolean,
      default: false
    },
    getContainer: {
      type: [String, Function],
      default: 'body'
    },
    headerStyle: {
      type: Object,
      default: undefined
    },
    height: {
      type: [String, Number],
      default: 378
    },
    keyboard: {
      type: Boolean,
      default: true
    },
    mask: {
      type: Boolean,
      default: true
    },
    maskClosable: {
      type: Boolean,
      default: true
    },
    maskStyle: {
      type: Object,
      default: () => ({})
    },
    placement: {
      type: String,
      default: 'right'
    },
    push: {
      type: [Boolean, Object],
      default: () => ({ distance: 180 })
    },
    size: {
      type: String,
      default: 'default'
    },
    style: {
      type: Object,
      default: undefined
    },
    visible: {
      type: Boolean,
      default: undefined
    },
    width: {
      type: [String, Number],
      default: 378
    },
    zIndex: {
      type: Number,
      default: 1000
    }
  },
  setup(props, ctx) {
    const slots = useSlots();
    const afterVisibleChangeHandler = (visible) => {
      ctx.emit('afterVisibleChange', visible)
    }
    const closeHandler = (e) => {
      ctx.emit('close', e)
    }
    const showVisible = computed({
      get: () => props.visible,
      set: (val) => {
        ctx.emit('update:visible', val)
      }
    })
    return{
      slots,
      afterVisibleChangeHandler,
      closeHandler,
      showVisible
    }
  }
})
</script>

<style scoped>

</style>
