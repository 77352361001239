<template>
  <a-tree class="cgs-design-tree" v-bind="attrs">
    <template v-for="(_, name) in slots" #[name]="scopedData">
      <slot :name='name' v-bind="scopedData"></slot>
    </template>
  </a-tree>
</template>

<script>
  import {
    defineComponent,
    useAttrs,
    useSlots
  } from 'vue'

  export default defineComponent({
    name: "cgsTree",
    props: {},
    setup() {
      const slots = useSlots();
      const attrs = useAttrs();
      return {
        slots,
        attrs
      }
    }
  })
</script>

<style lang="less">
  @import "~@style/less/theme.less";

  .cgs-design-tree.ant-tree {
    color: @font-color-1;
    background: transparent;
  }

  .cgs-design-tree.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background: @selected-color;
  }

  .cgs-design-tree.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
    color: @font-color-3;
  }

  .cgs-design-tree.ant-tree .ant-tree-node-content-wrapper:hover{
    background: @selected-color-08;
  }
  .cgs-design-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner{
    background: @primary-color;
    border-color: @primary-color;
  }
</style>