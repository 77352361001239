<template>
  <a-icon :rotate="rotate" :spin="spin" :style="style" :twoToneColor="twoToneColor" :component="component">
    <slot></slot>
  </a-icon>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsIcon",
  props: {
    rotate: {
      type: Number,
      default: undefined,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    style: {
      type: Object,
      default: undefined,
    },
    twoToneColor: {
      type: String,
      default: undefined,
    },
    component: {
      type: Object,
      default: undefined,
    }
  },
  setup() {
    const slots = useSlots();
    return{
      slots
    }
  }
})
</script>

<style scoped>

</style>
