<template>
  <a-rate :allowClear="allowClear" :allowHalf="allowHalf" :autofocus="autofocus" :count="count" :disabled="disabled"
          :tooltips="tooltips" v-model:value="showValue" @blurHandler="blurHandler" @changeHandler="changeHandler"
          @focusHandler="focusHandler" @hoverHandler="hoverHandler" @keydownHandler="keydownHandler">
    <template #character v-if="'character' in slots">
      <slot name="character"></slot>
    </template>
    <slot></slot>
  </a-rate>
</template>

<script>
import {
  computed,
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsRate",
  props: {
    allowClear: {
      type: Boolean,
      default: true
    },
    allowHalf: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltips: {
      type: Array,
      default: undefined
    },
    value: {
      type: Number,
      default: undefined
    },
  },
  setup(props, ctx){
    const slots = useSlots();
    const blurHandler = () => {
      ctx.emit('blur')
    }
    const changeHandler = (value) => {
      ctx.emit('change', value)
    }
    const focusHandler = () => {
      ctx.emit('focus')
    }
    const hoverHandler = (value) => {
      ctx.emit('hover', value)
    }
    const keydownHandler = (event) => {
      ctx.emit('keydown', event)
    }
    const showValue = computed({
      get: () => props.value,
      set: (val) => {
        ctx.emit('update:value', val)
      }
    })
    return{
      slots,
      blurHandler,
      changeHandler,
      focusHandler,
      hoverHandler,
      keydownHandler,
      showValue
    }
  }
})
</script>

<style scoped>

</style>
