<template>
  <a-breadcrumb :itemRender="itemRender" :params="params" :routes="routes" @clickHandler="clickHandler">
    <template #separator v-if="(separator) in slots">
      <!-- 该组件中只能放入 a-breadcrumb-item 作为子组件，通过原本方式封装之后放进来不会被父组件解析 -->
      <slot name="separator"></slot>
      
    </template>
    <slot></slot>
  </a-breadcrumb>
</template>

<script>
import {
  defineComponent,
  useSlots
} from 'vue'

export default defineComponent({
  name: "cgsBreadcrumb",
  props: {
    itemRender: {
      type: Function,
      default: undefined
    },
    params:{
      type: Object,
      default: undefined
    },
    routes:{
      type: Object,
      default: undefined
    }
  },
  setup(props, ctx){
    const slots = useSlots();
    const clickHandler = (e) => {
      ctx.emit('click', e)
    }
    return{
      slots,
      clickHandler
    }
  }
})
</script>

<style scoped>

</style>
